import { FC } from 'react';
import styled from 'styled-components';

const Header = styled.h2`
    margin-top: 10px;
    margin-bottom: 25px;
    max-width: 370px;
`;
const Wrapper = styled.div`
    max-width: 575px;
    display: flex;
    flex-direction: column;
    margin: auto;
`;
const Text = styled.div`
    font-size: 17px;
    line-height: 25px;
`;

const Description: FC = () => (
    <Wrapper>
        <Header>Find focus with our unique maritime insight</Header>
        <Text>
            Illegal fishing, assets protection, and environmental crimes are maritime challenges faced by governments
            and businesses around the world. The vastness of the sea and the limited traceability of ships create large
            information gaps, adding to the complexity of these challenges. Satellite images could fill in some of the
            blanks, but the images are just as vast as the oceans themselves. To get actionable insights from this data
            is laborious work – this is where we come in.
            <br /> <br />
            We use machine learning to automatically detect ships in satellite images. The result of our sole maritime
            focus is in a specialized algorithm capable of generating training data. This makes us uniquely flexible in
            terms of dat a source, scalability, and the degree of human verification. Creating one-of-a kind maritime
            insight suited to your needs.
        </Text>
    </Wrapper>
);

export default Description;
