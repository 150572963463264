import React, { useContext } from 'react';
import DeckGL from '@deck.gl/react/typed';
import styled from 'styled-components';
import Map, { Layer, Source } from 'react-map-gl';
import { AppContext } from './Storyteller';
import { StoryPopup } from './StoryPopup';
import { MapboxLayerType } from './types/context';

const MapContainer = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
`;

const MAPBOX_ACCESS_TOKEN =
    'pk.eyJ1IjoidmFrZS1tYXBib3giLCJhIjoiY2xmdGZ0NGQyMDBpdzNxcDBsYzBwZGY1NSJ9.uAr2Is_O91JVDhEczIDKqg';

const StyledStoryPopup = styled(StoryPopup)<{ transitionDuration: number }>`
    z-index: 10000;
    visibility: hidden;
    transition: visibility ${(props) => props.transitionDuration}ms ease-in ${(props) => props.transitionDuration}ms;
`;

const MapboxStudioRasterLayer: React.FC<{ layer: MapboxLayerType }> = ({ layer }) => {
    return (
        <Source
            key={'source_' + layer.id}
            id={'source_' + layer.id}
            type="raster"
            url={'mapbox://' + layer.mapboxLayerId}
            tileSize={256}
        >
            <Layer
                id={layer.id}
                type="raster"
                layout={{ visibility: layer.visible ? 'visible' : 'none' }}
                paint={{
                    'raster-opacity-transition': {
                        duration: (layer.transitions && layer.transitions.mapbox) || 300,
                        delay: (layer.transitions && layer.transitions.mapbox) || 300,
                    },
                }}
            />
        </Source>
    );
};

const StoryMap: React.FC<{ mapStyle: string | undefined }> = ({ mapStyle }) => {
    const {
        state: { viewport, deckglLayers, popups, mapboxLayers },
        actions: { setViewport },
    } = useContext(AppContext);

    const onSetViewport = (vst: any) => {
        setViewport(vst);
    };

    return (
        <MapContainer>
            <DeckGL
                viewState={viewport as any}
                controller={true}
                width={'100%'}
                height={'100%'}
                onViewStateChange={(arg: any) => onSetViewport(arg.viewState)}
                layers={deckglLayers}
            >
                <>
                    <Map
                        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                        projection={{ name: 'mercator' }}
                        mapStyle={mapStyle ?? 'mapbox://styles/vake-mapbox/clftghatz005j01mhgqz4uroz'}
                    >
                        {mapboxLayers &&
                            mapboxLayers.map((layer: MapboxLayerType, i: any) => (
                                <MapboxStudioRasterLayer layer={layer} key={i} />
                            ))}
                        {popups && // when multiple popups, use actual popup, else uses sidebar
                            popups.length > 1 &&
                            popups.map((popup: any, i: any) => (
                                <StyledStoryPopup
                                    feature={popup.feature}
                                    anchor={popup.anchor}
                                    transitionDuration={(popup.transitions && popup.transitions.popup) || 0}
                                    key={i}
                                />
                            ))}
                    </Map>
                </>
            </DeckGL>
        </MapContainer>
    );
};

export default StoryMap;
