import React from 'react';
import styled from 'styled-components';

interface NotFoundProps {
    location: string;
}

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    text-align: center;
    margin: 50px auto;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Center = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Title = styled.h1`
    font-size: 32px;
    color: #ff5555;
`;

const Message = styled.p`
    font-size: 18px;
    margin-top: 10px;
`;

const NotFound: React.FC<NotFoundProps> = ({ location }) => {
    return (
        <Container>
            <Center>
                <Title>404 Not Found</Title>
                <Message>
                    The page at <code>{location}</code> could not be found.
                </Message>
            </Center>
        </Container>
    );
};

export default NotFound;
