import { Feature, FeatureCollection } from 'geojson';
import { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { DetectionImage, PlanetThumbnail, TargetOfInterestImage } from '../../../shared/components/Popup';
import { Border, BorderFreeRounded, Title } from '../styles/Wrappers';
import { Arrow } from '../interactiveReport/reportInfo/ReportInfo';
import constants from '../../../shared/constants';
import { CircleDot } from '../../../shared/styles/CircleDot';
import { ButtonLink } from '../../../shared/styles/Buttons';

const FocusedImageWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    aligh-items: center;
`;

const FocusedImage = styled.div`
    width: 360px;
    height: 360px;
    display: inline-block;
    overflow: hidden;
    border-radius: 5px;
`;

const InformationContainer = styled.div`
    margin: 5px 5px 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const Information = styled.div`
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.05);
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
`;

const Banner = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

const InformationType = styled(Information)`
    background-color: #525042;
`;
const SubTitle = styled.div`
    font-weight: 700;
`;

const SpaceOccuiperContainer = styled.div`
    display: flex;
`;

export const SideBar = styled.div`
    position: fixed;
    top: 50px;
    right: 5px;
    z-index: 1000;
    min-width: 175px;
    width: 20%;
    max-width: 325px;
    max-height: calc(100vh - 60px);
`;

const UnderTitle = styled.div`
    margin-top: 20px;
`;

const ImageHolder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 60vh;
    overflow-y: scroll;
    // padding-left: 5px;
`;

const ImageObjectWrapper = styled.span<{ $isSentinel?: boolean | null }>`
    display: flex;
    width: 95%;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    border: 2px solid transparent;
    background-color: ${(props) => (props.$isSentinel ? 'default' : '#1178aa')};
    &:hover {
        border: 2px solid #ecda80;
    }
    gap: 5px;
`;
const ImageWrapper = styled.div`
    width: 120px;
    height: 120px;
    display: inline-block;
    overflow: hidden;
    // width: 100%; /* Adjusts the width to fill the container */
    // height: auto; /* Maintains the aspect ratio */
    // object-fit: cover; /* Ensures the image covers the container without distortion */
    border-radius: 3px;
`;

const DateInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-grow: 1;
`;

export const TransformWrapper = styled.div<{ $farNorthScaling?: number | null }>`
    transform: scale(${(props) => (props.$farNorthScaling && props.$farNorthScaling > 69 ? 1.27 : 1.09)});
`;
const ButtonsHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;

const IdentificationButton = styled(ButtonLink)`
    width: 10vw;
    align-self: center;
    background-color: ${constants.colors.pallet.white};
    color: ${constants.colors.pallet.lightBlack};
    &:hover {
        background-color: rgba(255, 255, 255, 0.3);
        color: ${constants.colors.pallet.white};
    }
`;

const ShowingIndeces = styled.div`
    margin-top: 20px;
    height: 20px;
    text-align: center;
`;

export const ShipInfo: FC<{
    selectedFeature: Feature | null;
    setBoatMetaData: (value: Feature | null) => void;
    shipName: string | null;
    upsampleImage?: boolean;
}> = ({ selectedFeature, setBoatMetaData, shipName, upsampleImage }) => {
    const getSentinelHubUrl = () => {
        // Only works for S2 now. Can make it work for S1.
        const date = selectedFeature!.properties!.timestamp.slice(0, 10);
        const startOfDate = `${date}T00:00:00.000Z`;
        const endOfDate = `${date}T23:59:59.000Z`;
        return `https://apps.sentinel-hub.com/eo-browser/?zoom=15&lat=${selectedFeature!.properties!.lat}&lng=${
            selectedFeature!.properties!.lon
        }&themeId=DEFAULT-THEME&visualizationUrl=https://services.sentinel-hub.com/ogc/wms/42924c6c-257a-4d04-9b8e-36387513a99c&datasetId=S2L1C&fromTime=${startOfDate}&toTime=${endOfDate}`;
    };

    const formatTimestamp = (timestamp: string) => {
        const date = timestamp.split(' ');
        const datestring = date[1] + ' ' + date[2] + ' ' + date[3] + ', ' + date[4].slice(0, 5) + ' GMT';
        return datestring;
    };
    return (
        <BorderFreeRounded>
            <Banner>
                <Arrow
                    onClick={(e) => {
                        setBoatMetaData(null);
                    }}
                >
                    <img src="/images/icons/ArrowLeft.svg" alt="arrow left" />
                </Arrow>
                <Title>{shipName}</Title>
            </Banner>
            {selectedFeature && selectedFeature.properties ? (
                <>
                    <FocusedImageWrapper>
                        <FocusedImage>
                            {selectedFeature?.properties?.image_source !== 'planet_scope' ? (
                                <TransformWrapper $farNorthScaling={selectedFeature?.properties?.lat}>
                                    {selectedFeature.properties.image_path ? (
                                        <DetectionImage image_path={selectedFeature.properties.image_path} />
                                    ) : (
                                        <TargetOfInterestImage
                                            feature={selectedFeature.properties}
                                            upsampleImage={upsampleImage || false}
                                        />
                                    )}
                                </TransformWrapper>
                            ) : selectedFeature.properties.image_path ? (
                                <PlanetThumbnail image_path={selectedFeature.properties.image_path} />
                            ) : null}
                        </FocusedImage>
                    </FocusedImageWrapper>
                    <InformationContainer>
                        <SpaceOccuiperContainer>
                            {selectedFeature?.properties?.matching_label &&
                                selectedFeature?.properties?.object_class && (
                                    <InformationType>
                                        {getLegendDot(selectedFeature)}
                                        {`${selectedFeature.properties.matching_label} ${selectedFeature.properties.object_class}`}
                                    </InformationType>
                                )}
                            <div />
                        </SpaceOccuiperContainer>
                        {selectedFeature.properties.mmsi && (
                            <SpaceOccuiperContainer>
                                <Information>
                                    {/* <img src="/images/icons/MoreInfo.png" alt="no source" /> */}
                                    <b>MMSI </b>
                                    <span>{selectedFeature.properties.mmsi}</span>
                                </Information>
                                <div />
                            </SpaceOccuiperContainer>
                        )}
                        {typeof selectedFeature.properties.image_source === 'string' ? (
                            <SpaceOccuiperContainer>
                                <Information>
                                    <img src="/images/icons/satellite.svg" alt="no source" />
                                    <b>Satellite: </b>
                                    <span>{selectedFeature.properties?.image_source}</span>
                                </Information>
                                <div />
                            </SpaceOccuiperContainer>
                        ) : null}
                        {typeof selectedFeature.properties.lon === 'number' ? (
                            <SpaceOccuiperContainer>
                                <Information>
                                    <img src="/images/icons/longitude.svg" alt="no Longitude" />
                                    <SubTitle>Longitude: </SubTitle>
                                    <span>{Math.round(selectedFeature.properties?.lon * 100000000) / 100000000}</span>
                                </Information>
                                <div />
                            </SpaceOccuiperContainer>
                        ) : null}
                        {typeof selectedFeature?.properties?.lat === 'number' ? (
                            <SpaceOccuiperContainer>
                                <Information>
                                    <img
                                        onDoubleClick={() => window.open(getSentinelHubUrl(), '_blank')}
                                        src="/images/icons/latitude.svg"
                                        alt="no Latitude"
                                    />
                                    <SubTitle>Latitude: </SubTitle>
                                    <span>{Math.round(selectedFeature.properties?.lat * 100000000) / 100000000}</span>
                                </Information>
                                <div />
                            </SpaceOccuiperContainer>
                        ) : null}
                        {typeof selectedFeature.properties?.speed === 'number' ? (
                            <SpaceOccuiperContainer>
                                <Information>
                                    <img src="/images/icons/speedometer.svg" alt="no speed" />
                                    <SubTitle>AIS Speed:</SubTitle>
                                    <span>{selectedFeature.properties?.speed} kn</span>
                                </Information>
                                <div />
                            </SpaceOccuiperContainer>
                        ) : null}
                        {typeof selectedFeature.properties?.ais_length === 'number' ? (
                            <SpaceOccuiperContainer>
                                <Information>
                                    {/* <img src="/images/icons/speedometer.svg" alt="no speed" /> */}
                                    <SubTitle>AIS Length:</SubTitle>
                                    <span>{selectedFeature.properties?.ais_length} m</span>
                                </Information>
                                <div />
                            </SpaceOccuiperContainer>
                        ) : null}
                        {typeof selectedFeature.properties?.length === 'number' ? (
                            <SpaceOccuiperContainer>
                                <Information>
                                    {/* <img src="/images/icons/speedometer.svg" alt="no speed" /> */}
                                    <SubTitle>Predicted Length:</SubTitle>
                                    <span>{selectedFeature.properties?.length} m</span>
                                </Information>
                                <div />
                            </SpaceOccuiperContainer>
                        ) : null}
                        {typeof selectedFeature.properties?.timestamp === 'string' ? (
                            <SpaceOccuiperContainer>
                                <Information>
                                    <img src="/images/icons/calendar.svg" alt="no timestamp" />
                                    <SubTitle>Time: </SubTitle>
                                    <span>
                                        {formatTimestamp(new Date(selectedFeature.properties?.timestamp).toUTCString())}
                                    </span>
                                </Information>
                                <div />
                            </SpaceOccuiperContainer>
                        ) : null}
                        {typeof selectedFeature.properties?.image_path === 'string' &&
                        selectedFeature.properties?.image_source === 'sentinel2' ? (
                            <IdentificationButton
                                href={
                                    window.location.origin +
                                    '/clearview?uuid=' +
                                    JSON.stringify([
                                        selectedFeature.properties.image_path.split('sentinel2/')[1].split('.png')[0],
                                    ])
                                }
                                target={'_blank'}
                            >
                                Use VAKE ClearView to identify
                            </IdentificationButton>
                        ) : null}
                    </InformationContainer>
                </>
            ) : (
                <InformationContainer>
                    <div style={{ height: '50px' }}>missing image information</div>
                </InformationContainer>
            )}
        </BorderFreeRounded>
    );
};

const getLegendDot = (feature: Feature) => {
    if (!feature.properties) return;
    if (feature.properties.object_class === 'platform' || feature.properties.object_class === 'windmill') {
        return <CircleDot {...constants.colors.markers.staticObjects} />;
    } else if (feature.properties.matching_label === 'undetected') {
        return <CircleDot {...constants.colors.markers.undetectedAis} />;
    } else if (feature.properties.matching_label === 'matched') {
        return <CircleDot {...constants.colors.markers.matchedVessels} />;
    } else if (feature.properties.matching_label === 'dark_vessel') {
        return <CircleDot {...constants.colors.markers.darkShips} />;
    }
};

export const OverwatchRight: FC<{
    aisPositions: FeatureCollection | undefined;
    matchedVessels: FeatureCollection | undefined;
    darkVessels: FeatureCollection | undefined;
    staticObjects: FeatureCollection | undefined;
    selectedFeature: Feature | null;
    setSelectedFeature: (value: Feature | null) => void;
    setHoveredFeature: (value: Feature | null) => void;
    mmsi: string;
    shipName: string | null;
}> = ({
    aisPositions,
    matchedVessels,
    darkVessels,
    staticObjects,
    selectedFeature,
    setSelectedFeature,
    setHoveredFeature,
    shipName,
}) => {
    const [positions, setPositions] = useState<Feature[]>([]);
    const [numCaptures, setNumCaptures] = useState<number | null>(null);
    const [startIndex, setStartIndex] = useState<number>(0);
    const [endIndex, setEndIndex] = useState<number>(-1);

    const buttonIncrement = 30;

    const all_positions = useMemo(() => {
        return [
            ...(matchedVessels?.features || []),
            ...(darkVessels?.features || []),
            ...(aisPositions?.features || []),
            ...(staticObjects?.features || []),
        ];
    }, [aisPositions, matchedVessels, darkVessels, staticObjects]);

    useEffect(() => {
        if (all_positions) {
            setPositions(all_positions);
            setNumCaptures(all_positions.length);
            setEndIndex(all_positions.length >= 30 ? 30 : all_positions.length);
            setStartIndex(0);
        }
    }, [all_positions]);

    return (
        <SideBar>
            {!selectedFeature ? (
                <BorderFreeRounded>
                    <Border>
                        <Title>Overwatch</Title>
                        <UnderTitle>{numCaptures ? numCaptures + ' satellite images' : '  '}</UnderTitle>
                    </Border>
                    <ImageHolder>
                        {positions.slice(startIndex, endIndex).map((item, index) => {
                            const isSentinel = item.properties?.image_source !== 'planet_scope'; // TODO: Needs to be updated if we add more sources
                            return (
                                <ImageObjectWrapper
                                    key={index}
                                    $isSentinel={isSentinel}
                                    onClick={() => {
                                        setSelectedFeature(item);
                                    }}
                                    onMouseEnter={() => setHoveredFeature(item)}
                                    onMouseLeave={() => setHoveredFeature(null)}
                                >
                                    <ImageWrapper>
                                        {isSentinel ? (
                                            <TransformWrapper $farNorthScaling={item.properties?.lat}>
                                                {item.properties?.image_path ? (
                                                    <DetectionImage image_path={item.properties.image_path} />
                                                ) : (
                                                    <TargetOfInterestImage feature={{ ...item.properties }} />
                                                )}
                                            </TransformWrapper>
                                        ) : item.properties?.image_path ? (
                                            <PlanetThumbnail image_path={item.properties.image_path} />
                                        ) : null}
                                    </ImageWrapper>
                                    {getLegendDot(item)}
                                    <DateInfo>
                                        <span>{item.properties?.mmsi}</span>
                                        {typeof item.properties?.timestamp === 'string' ? (
                                            <span>
                                                {new Date(item.properties?.timestamp).toUTCString().split(' ')[4] +
                                                    ' GMT'}
                                            </span>
                                        ) : null}
                                    </DateInfo>
                                </ImageObjectWrapper>
                            );
                        })}
                    </ImageHolder>
                    {numCaptures ? (
                        <BorderFreeRounded>
                            <ShowingIndeces>
                                Images {startIndex + 1} to {endIndex}
                            </ShowingIndeces>
                            <ButtonsHolder>
                                <Arrow
                                    disabled={startIndex === 0}
                                    onClick={() => {
                                        const newStartIndex =
                                            startIndex - buttonIncrement >= 0 ? startIndex - buttonIncrement : 0;
                                        const newEndIndex =
                                            newStartIndex <= 0
                                                ? buttonIncrement > positions.length
                                                    ? positions.length
                                                    : buttonIncrement
                                                : endIndex - buttonIncrement;

                                        setStartIndex(newStartIndex);
                                        setEndIndex(newEndIndex);
                                    }}
                                >
                                    <img src="/images/icons/ArrowLeft.svg" alt="Previous date" />
                                </Arrow>
                                <Arrow
                                    disabled={endIndex === positions.length}
                                    onClick={() => {
                                        const newEndIndex =
                                            endIndex + buttonIncrement <= numCaptures
                                                ? endIndex + buttonIncrement
                                                : numCaptures;
                                        const newStartIndex =
                                            newEndIndex >= numCaptures
                                                ? numCaptures - buttonIncrement < 0
                                                    ? 0
                                                    : numCaptures - buttonIncrement
                                                : startIndex + buttonIncrement;

                                        setStartIndex(newStartIndex);
                                        setEndIndex(newEndIndex);
                                    }}
                                >
                                    <img src="/images/icons/ArrowRight.svg" alt="right arrow" />
                                </Arrow>
                            </ButtonsHolder>
                        </BorderFreeRounded>
                    ) : null}
                </BorderFreeRounded>
            ) : (
                <ShipInfo selectedFeature={selectedFeature} setBoatMetaData={setSelectedFeature} shipName={shipName} />
            )}
        </SideBar>
    );
};

export default OverwatchRight;
