import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';

import { Button } from '../../../shared/styles/Buttons';
import constants from '../../../shared/constants';
import { RoundedWrapper, PaddingWrapper } from '../styles/Wrappers';
import { FilterButton } from '../styles/Buttons';
import { postReportRequest } from '../../../requests/crm';
import { useInput } from '../components/useInput';
import { useInputCheckbox } from '../components/useInputCheckbox';
import { Link } from 'react-router-dom';
import { OrderOverwatchMap } from './OrderOWMap';
import { Feature } from 'geojson';
import { DrawButton } from '../../../shared/components/DrawToggler';

const Inn = styled.input<{ error: string[] }>`
    width: 100%;
    padding: 12px 16px;
    margin: 8px 0px;
    box-sizing: border-box;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);
    border: 2px solid;
    border-color: ${(props) => (props.error.length > 0 ? constants.colors.pallet.error : 'rgba(0, 0, 0, 0.5)')};
    font-size: 16px;
    line-height: 24px;
    color: white;
    position: relative;

    &:focus {
        outline: none;
    }

    #consentNewsletter {
        width: 16px;
        height: 16px;
        align-self: center;
        margin: 0;
    }
`;

const InputWrapper = styled.div`
    display: flex;
    justify-content: start;
`;

const InnBox = styled.input<{ error?: boolean }>`
    width: 16px;
    height: 16px;
    align-self: center;
    margin: 0;
    border: 2px solid;
    border-color: ${(props) => (props.error ? constants.colors.pallet.error : 'rgba(0, 0, 0, 0.5)')};
    line-height: 24px;
`;

const BoxLabel = styled.h4<{ error?: boolean }>`
    margin-left: 8px;
    font-size: 10px;
    line-height: 25px;
    color: ${(props) => (props.error ? constants.colors.pallet.error : 'white')};
`;

const FormData = styled.div`
    position: absolute;
    top: 50px;
    left: 5px;
    z-index: 1000;
    width: 465px;
    max-width: calc(100% - 50px);
    label {
        margin-bottom: 12px;
    }
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        display: none;
    }
`;

const StyledButton = styled(Button)<{ active?: Boolean }>`
    background: ${(props) => !props.active && 'grey'};
    border-color: ${(props) => !props.active && 'grey'};
    cursor: ${(props) => !props.active && 'default'};
    &:hover {
        box-shadow: none;
    }
`;
const TopBorder = styled(PaddingWrapper)`
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    label {
        margin-bottom: 8px;
    }
`;
const HighlightedTopBorder = styled(TopBorder)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #42454d;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    img {
        width: 5px;
        opacity: 0.5;
    }
    &:hover {
        box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
    }
`;

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const DateWrapper = styled.div`
    position: absolute;
`;

const Error = styled.span`
    color: ${constants.colors.pallet.error};
    a {
        color: ${constants.colors.pallet.error};
    }
`;

const LinkWithoutUnderline = styled(Link)`
    text-decoration: none;
`;

const RequestReport: React.FC<{}> = () => {
    const name = useInput('', 'Name', [required]);
    const companyName = useInput('', 'Company name', [required]);
    const email = useInput('', 'Email', [required, validEmail]);
    const consentPrivacy = useInputCheckbox(false, 'Privacy checkbox', [isChecked]);
    const consentNewsletter = useInputCheckbox(false, 'Newsletter checkbox', []);
    const [successfulSubmit, setSuccessfulSubmit] = useState<Boolean | undefined>(undefined);
    const [polygonWKT, setPolygonWKT] = useState<string | null>(null);
    const [timeFrame, setTimeFrame] = useState<string>('');
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [openDatePicker, setOpenDatePicker] = useState<Boolean>(false);
    const [drawMode, toggleDrawMode] = useState<boolean>(false);
    const [polygon, setPolygon] = useState<Feature | undefined>(undefined);
    useEffect(() => {
        document.title = 'Order report';
    }, []);

    const validateFields = () => {
        return Boolean(
            name.validation.touched &&
                !name.validation.errors.length &&
                email.validation.touched &&
                !email.validation.errors.length &&
                companyName.validation.touched &&
                !companyName.validation.errors.length &&
                consentPrivacy.checked &&
                polygonWKT &&
                timeFrame.length > 0
        );
    };
    const allFieldsAreValid = validateFields();

    const getFieldErrors = () => {
        return email.validation.errors.concat(
            name.validation.errors,
            companyName.validation.errors,
            consentPrivacy.validation.errors
        );
    };

    const onChange = (date: [Date | null, Date | null]) => {
        const [start, end] = date;
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            setTimeFrame(`${start.toLocaleString().split(',')[0]} to ${end.toLocaleString().split(',')[0]}`);
            setOpenDatePicker(false);
        }
    };

    const submitClickup = () => {
        consentPrivacy.validation.validate();
        if (allFieldsAreValid) {
            postReportRequest(
                name.value,
                email.value,
                companyName.value,
                consentNewsletter.checked,
                polygonWKT!,
                timeFrame,
                (valid: Boolean) => {
                    setSuccessfulSubmit(valid);
                }
            );
        }
    };

    const ResetFields = useCallback(() => {
        email.reset();
        name.reset();
        companyName.reset();
        consentPrivacy.reset();
        consentNewsletter.reset();
        setTimeFrame('');
        setStartDate(null);
        setEndDate(null);
        setPolygonWKT(null);
        setPolygon(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (successfulSubmit) {
            ResetFields();
        }
    }, [ResetFields, successfulSubmit]);

    useEffect(() => {
        if (polygon?.geometry.type === 'Polygon') {
            setPolygonWKT(
                `POLYGON((${polygon.geometry.coordinates[0]
                    .map((coord: number[]) => coord[0] + ' ' + coord[1])
                    .join(', ')}))`
            );
        }
    }, [polygon]);
    return (
        <Wrapper>
            <FormData>
                <RoundedWrapper>
                    <PaddingWrapper>
                        <h2>Request a Report</h2>
                        <p>
                            By processing multispectral satellite images with cutting edge machine learning, we give you
                            automated ship detections – no strings attached. Discover how our ship detections provides a
                            focal point for your maritime operations. Fill in your details and make the blue area
                            overlay your area of interest.
                        </p>
                    </PaddingWrapper>
                    <LinkWithoutUnderline to="/report">
                        <HighlightedTopBorder>
                            <span>Curious about how a report looks like?</span>
                            <img src="images/icons/ArrowRight.svg" alt="Link to report" />
                        </HighlightedTopBorder>
                    </LinkWithoutUnderline>
                </RoundedWrapper>
                <RoundedWrapper>
                    <PaddingWrapper>
                        <label>Receiver information</label>
                        <Inn placeholder="What's your full name?..." error={name.validation.errors} {...name.bind} />
                        <Inn
                            id="email"
                            placeholder="What's your email?..."
                            error={email.validation.errors}
                            {...email.bind}
                        />
                        <Inn
                            placeholder="What's your company's name?"
                            error={companyName.validation.errors}
                            {...companyName.bind}
                        />
                        <InputWrapper style={{ marginTop: '16px' }}>
                            <InnBox
                                type="checkbox"
                                error={consentPrivacy.validation.errors.length > 0}
                                {...consentPrivacy.bind}
                            ></InnBox>
                            <BoxLabel>
                                I AGREE TO VAKE'S{' '}
                                <a href="/privacy-policy" target="_blank">
                                    PRIVACY POLICY
                                </a>{' '}
                                <div style={{ display: 'inline-block', fontSize: '10px', marginLeft: '4px' }}>
                                    (required)
                                </div>
                            </BoxLabel>
                        </InputWrapper>
                        <InputWrapper style={{ marginTop: '4px' }}>
                            <InnBox type="checkbox" error={false} {...consentNewsletter.bind}></InnBox>
                            <BoxLabel>YES, I ALSO WANT TO SUBSCRIBE TO THE OCCASIONAL NEWSLETTER</BoxLabel>
                        </InputWrapper>
                    </PaddingWrapper>
                    <TopBorder>
                        <label>Select Timeframe and Area</label>
                        <ButtonWrapper>
                            <FilterButton
                                selected={Boolean(timeFrame !== 'latest')}
                                onClick={() => {
                                    setOpenDatePicker(!openDatePicker);
                                }}
                            >
                                {timeFrame ? timeFrame : 'Choose dates'}
                            </FilterButton>
                            {openDatePicker && (
                                <DateWrapper>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={onChange}
                                        startDate={startDate}
                                        endDate={endDate}
                                        selectsRange={true}
                                        inline
                                    />
                                </DateWrapper>
                            )}
                            <DrawButton
                                isFinished={polygon !== undefined}
                                isDrawMode={drawMode}
                                onClick={() => {
                                    setPolygon(undefined);
                                    toggleDrawMode(!drawMode);
                                }}
                            />
                            {polygon && (
                                <StyledButton
                                    background={constants.colors.pallet.blue}
                                    active={allFieldsAreValid}
                                    disabled={!allFieldsAreValid}
                                    onClick={() => submitClickup()}
                                >
                                    Request report
                                </StyledButton>
                            )}
                        </ButtonWrapper>
                    </TopBorder>
                    {!allFieldsAreValid &&
                        getFieldErrors()
                            .slice(0, 1)
                            .map((err) => (
                                <PaddingWrapper style={{ color: constants.colors.pallet.error }}>{err}</PaddingWrapper>
                            ))}

                    {polygon && successfulSubmit === false && (
                        <PaddingWrapper>
                            <Error>
                                Error when requesting report. If the problem continues, please contact us at{' '}
                                <a href="mailto:connect@vake.ai">connect@vake.ai</a>
                            </Error>
                        </PaddingWrapper>
                    )}
                    {polygon && successfulSubmit === true && (
                        <PaddingWrapper>Request sent successfully. We will get back to you ASAP.</PaddingWrapper>
                    )}
                </RoundedWrapper>
            </FormData>
            <OrderOverwatchMap
                drawMode={drawMode}
                toggleDrawMode={toggleDrawMode}
                polygon={polygon}
                setPolygon={setPolygon}
            />
        </Wrapper>
    );
};

export default RequestReport;

//Validation:
const required = (text: string, fieldName: string) => {
    if (text) {
        return '';
    }
    return `${fieldName} is required`;
};
const isChecked = (value: boolean, fieldName: string) => {
    if (value) {
        return '';
    }
    return `${fieldName} is required to be checked`;
};

const validEmail = (value: string, fieldName: string) => {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
        return '';
    }
    return `${fieldName} is not valid`;
};
