import { FC } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { useAuth } from '../auth/AuthProvider';
import { getRequestHeaders, vake_api_server_url } from '../requests/helpers';
import constants from '../shared/constants';
import { Site, GridPageDisplay } from './Admin';
import { AbsoluteSpinner } from './Report/interactiveReport/PathfinderReport';

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    color: rgba(255, 255, 255, 0.5);
    margin: auto;
    margin-top: 120px;
    margin-bottom: 120px;
    max-width: ${constants.sizes.container};
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        width: 100%;
    }
`;

const useGetUserSites = () => {
    const { token } = useAuth();
    const url = `${vake_api_server_url}/user/get-user-sites`;
    return useQuery<Site[]>(['admin_sites'], async () => {
        const res = await fetch(url, {
            credentials: 'include',
            headers: getRequestHeaders(token),
        });
        if (!res.ok) {
            throw new Error(res.statusText);
        }

        return await res.json();
    });
};

const UserPanel: FC = () => {
    const { data: userSites, isLoading, isError } = useGetUserSites();
    if (isError) {
        return (
            <Wrapper>
                <div>Fetching data failed</div>
            </Wrapper>
        );
    }
    if (isLoading) {
        return (
            <Wrapper>
                <AbsoluteSpinner />
            </Wrapper>
        );
    }
    return <Wrapper>{typeof userSites !== 'undefined' ? <GridPageDisplay websites={userSites} /> : null}</Wrapper>;
};

export default UserPanel;
