import { FC } from 'react';
import styled from 'styled-components';
import constants from '../../../shared/constants';
import { ButtonLink } from '../../../shared/styles/Buttons';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto;
    width: ${constants.sizes.container};
    max-width: calc(100% - 50px);

    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        flex-direction: column;
        align-items: center;
        max-width: 100%;
    }
`;
const ContactWrapper = styled.div<{ highlighted?: boolean }>`
    display: flex;
    flex-direction: column;
    background: ${(props) =>
        props.highlighted && 'radial-gradient(42.95% 171.27% at 86.22% 0%, #00A1FF 0%, rgba(0, 161, 255, 0.4) 100%)'};
    mix-blend-mode: difference;
    margin-top: 150px;
    p {
        width: calc(50% - 75px);
        margin: 0;
        margin-bottom: 10px;
    }
    h2 {
        margin: 0 0 15px 0;
    }
    img {
        width: 131px;
        opacity: 0.5;
    }
    span {
        opacity: 0.5;
        margin-bottom: 5px;
    }

    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        width: calc(100% - 50px);
        p {
            margin: 0;
            width: 100%;
        }
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Contact: FC = () => {
    return (
        <Wrapper>
            <ContactWrapper>
                <TextWrapper>
                    <h2>Partnerships</h2>
                    <p>
                        We know that total Maritime Domain Awareness is a combined effort. That's why we're constantly
                        on the lookout for others working towards the same goal – reaching from NGOs to tipping and
                        cueing for commercial satellite providers. Together we create accountability at sea.
                    </p>
                </TextWrapper>
                <span>Currently integrating data from</span>
                <img src="images/icons/Recognized/Airbus.png" alt="Airbus" />
                <div>
                    <ButtonLink
                        style={{ marginTop: '40px' }}
                        onClick={() =>
                            (window as any).sa_event(
                                () => 'reach_out_email_partner_on_' + window.document.location.search
                            )
                        }
                        href="mailto:connect@vake.ai"
                        background="white"
                    >
                        Reach out to us
                    </ButtonLink>
                </div>
            </ContactWrapper>
        </Wrapper>
    );
};

export default Contact;
