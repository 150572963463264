import styled from 'styled-components';
import constants from '../../../shared/constants';
export const FilterButton = styled.div<{ selected?: Boolean }>`
    background-color: ${(props) => (props.selected ? 'white' : 'rgba(255, 255, 255, 0.15)')};
    border: 2px solid ${(props) => (props.selected ? 'white' : 'rgba(255, 255, 255, 0.2)')};
    color: ${(props) => (props.selected ? constants.colors.pallet.black : 'white')};
    padding: 10px 15px;
    text-align: center;
    font-size: 17px;
    margin: 4px 2px;
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
    &:hover {
        box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
    }
    &:active {
        outline: none;
        border: none;
    }
    &:focus {
        outline: 0;
    }
`;
