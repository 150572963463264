import React from 'react';
import { createRoot } from 'react-dom/client';
import mapboxgl from 'mapbox-gl';
import { FeatureCollection } from 'geojson';
import { ReportType } from '../../utils/enums';
import { parseGeoJsonPropertiesNullValues } from '../../utils/map';
import { QueryClientProvider } from 'react-query';
import { ReportPopup, TargetsOfInterestPopup } from './Popup';
import { queryClient } from '../../App';

export interface ILayer {
    id: string;
    name: string;
    date: string;
    static?: boolean;
    data: FeatureCollection | null;
    fill: any;
    stroke?: string;
    filter?: string[];
    fillHover?: 'string';
    strokeHover?: 'string';
    type: 'line' | 'circle' | 'fill';
    fillOpacity?: number;
    popupType?: 'detection' | 'toi';
    circleRadius?: number;
    cluster?: boolean;
}

export const addFillLayer = (
    currMap: mapboxgl.Map,
    data: any,
    id: string,
    fill: string,
    fillOpacity?: number,
    filter?: string[]
) => {
    if (currMap && !currMap.getSource(id)) {
        let layerObj: any = {
            id: id,
            type: 'fill',
            source: id,
            layout: {
                // make layer visible by default
                visibility: 'visible',
            },
            paint: {
                'fill-color': fill,
                'fill-opacity': fillOpacity ? fillOpacity : 0.3,
            },
        };
        if (filter) {
            layerObj['filter'] = filter;
        }
        currMap
            .addSource(id, {
                type: 'geojson',
                data: data,
            })
            .addLayer(layerObj);
    }
};

export const addPopup = (
    currMap: mapboxgl.Map,
    layer_id: string,
    popupRef: React.MutableRefObject<mapboxgl.Popup>,
    reportType: ReportType,
    popupType: 'detection' | 'toi',
    setHiglightedFeatureVisibility: (visible: boolean) => void,
    isAdmin?: boolean
) => {
    currMap.on('click', layer_id, (e) => {
        if (e.features && e.features.length) {
            const feature = e.features[0];
            feature.properties = parseGeoJsonPropertiesNullValues(feature.properties);
            // create popup node
            const popupNode = document.createElement('div');
            const popupRoot = createRoot(popupNode);

            popupRoot.render(
                <QueryClientProvider client={queryClient}>
                    {popupType === 'detection' ? (
                        <ReportPopup feature={feature.properties} reportType={reportType} isAdmin={isAdmin} />
                    ) : (
                        <TargetsOfInterestPopup feature={feature.properties} reportType={reportType} />
                    )}
                </QueryClientProvider>
            );
            let popup = new mapboxgl.Popup({ offset: 15 });

            // set popup on map
            popupRef.current = popup.setLngLat(e.lngLat).setDOMContent(popupNode).addTo(currMap);
            popup.on('close', (e: any) => {
                setHiglightedFeatureVisibility(false);
            });
        }
    });
};

export const highlightFeature = (
    currMap: mapboxgl.Map,
    feature: mapboxgl.MapboxGeoJSONFeature,
    fill: string,
    setHiglightedFeatureVisibility: (x: boolean) => void
) => {
    if (typeof currMap.getLayer('selectedCircle') !== 'undefined') {
        currMap.removeLayer('selectedCircle');
        currMap.removeSource('selectedCircle');
    }
    currMap.addSource('selectedCircle', {
        type: 'geojson',
        data: feature,
    });
    currMap.addLayer({
        id: 'selectedCircle',
        type: 'circle',
        source: 'selectedCircle',
        layout: {
            // make layer visible by default
            visibility: 'visible',
        },
        paint: {
            'circle-radius': 20,
            'circle-stroke-color': fill,
            'circle-stroke-width': 2,
            'circle-opacity': 0.4,
            'circle-stroke-opacity': 0.4,

            // The feature-state dependent circle-color expression will render
            // the color according to its magnitude when
            // a feature's hover state is set to true
            'circle-color': fill,
        },
    });
    setHiglightedFeatureVisibility(true);
};
