import { FC, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import constants from '../constants';

const pulse = keyframes`
    0% {
    -moz-box-shadow: 0 0 0 0 rgba(20, 255, 0, 0.4);
    box-shadow: 0 0 0 0 rgba(20, 255, 0, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(20, 255, 0, 0);
      box-shadow: 0 0 0 10px rgba(20, 255, 0, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(20, 255, 0, 0);
      box-shadow: 0 0 0 0 rgba(20, 255, 0, 0);
  }
`;

const Wrapper = styled.div`
    display: flex;
    max-width: ${constants.sizes.container};
    padding: 0px 13px;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 62px;
    margin-bottom: 20px;
    min-height: 35px;
    align-items: center;
`;

const GreenIcon = styled.div`
    border-radius: 100%;
    height: 11px;
    width: 11px;
    background: ${constants.colors.pallet.green};
    margin-right: 10px;
    box-shadow: 0 0 0 rgba(20, 255, 0, 0.4);
    animation: ${pulse} 2s infinite;
`;

const Text = styled.span`
    font-weight: 700;
    color: white !important;
    font-size: 13px;
`;

const Analysing: FC = () => {
    const mgrs_tiles = [
        '32VNK', //Skagerakk
        '30UXA', //English channel
        '40RDP', //Fujaira Iran
        '48NUG', //Singapore
        '56HLH', //Sydney
        '17SNR', //Savanna(USA)
    ];
    function FormatNumber(number: number) {
        return number < 10 ? '0' + number.toString() : number.toString();
    }

    function getRandomTile() {
        return mgrs_tiles[Math.floor(Math.random() * mgrs_tiles.length)];
    }
    function getDate() {
        return new Date(Date.now() - (5 * 60 * 1000 + 7 * 60 * 60 * 1000));
    }
    function updateValues() {
        let date = getDate();
        setYear(date.getFullYear());
        setMonth(FormatNumber(date.getMonth() + 1));
        setDay(FormatNumber(date.getDate()));
        setHours(FormatNumber(date.getHours()));
        setMinutes(FormatNumber(date.getMinutes()));
        setSeconds(FormatNumber(date.getSeconds()));
        setTile(getRandomTile());
    }
    const [year, setYear] = useState<number | undefined>(undefined);
    const [month, setMonth] = useState<string | undefined>(undefined);
    const [day, setDay] = useState<string | undefined>(undefined);
    const [hours, setHours] = useState<string | undefined>(undefined);
    const [minutes, setMinutes] = useState<string | undefined>(undefined);
    const [seconds, setSeconds] = useState<string | undefined>(undefined);
    const [tile, setTile] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!year || !month || !day || !hours || !minutes || !seconds || !tile) updateValues();
        setInterval(function () {
            //Change tile every minute
            updateValues();
        }, 60 * 1000); // updates text every minute
    });

    let value = `Now analysing S2A_${tile}_${year}${month}${day}${hours}${minutes}${seconds}`;
    return (
        <Wrapper>
            <GreenIcon />
            <Text>{value}</Text>
        </Wrapper>
    );
};

export default Analysing;
