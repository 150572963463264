import { FC } from 'react';
import styled from 'styled-components';
import constants from '../../../shared/constants';
import { Button } from '../../../shared/styles/Buttons';
import Analysing from '../../../shared/components/Analysing';
import { Link } from 'react-router-dom';

const Heading = styled.h1`
    margin-bottom: 20px;
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        margin-top: 60px;
    }
`;
const Wrapper = styled.div`
    width: 100%;
    margin: auto;
    display: flex;
    max-width: ${constants.sizes.container};
`;

const ContentWrapper = styled(Wrapper)`
    flex-direction: column;
    margin: auto;
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        max-width: calc(100% - 50px);
    }
`;

const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 200px auto;
    max-width: 650px;
    margin-left: 20px;
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        width: 100%;
        margin: 0;
        margin-bottom: 80px;
    }
`;
const ImageWrapper = styled.div`
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        background-image: url(/images/SatelliteMobile.png);
        background-size: 100%;
        height: calc(1.53 * 100vw);
    }
`;

const Description = styled.span`
    max-width: 395px;
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        margin-top: 25px;
    }
`;

const ButtonWrapper = styled.div`
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        margin-top: 20px;
    }
`;

const Intro: FC = () => {
    return (
        <Wrapper>
            <ColumnWrapper>
                <ImageWrapper>
                    <ContentWrapper>
                        <Heading>Processing oceans for you</Heading>
                        <div style={{ display: 'flex' }}>
                            <Analysing />
                        </div>
                    </ContentWrapper>
                </ImageWrapper>
                <ContentWrapper>
                    <Description>
                        Total Maritime Domain Awareness is a combined effort. We do our part by processing multispectral
                        satellite images with cutting edge machine learning. Our ship detections provides a focal point
                        for your maritime operations – wherever you are.
                    </Description>
                    <ButtonWrapper>
                        <div>
                            <Link to="/report">
                                {' '}
                                <Button
                                    background={constants.colors.pallet.blue}
                                    onClick={() => {
                                        (window as any).sa_event(
                                            () => 'req_product_demo_intro_on_' + window.document.location.search
                                        );
                                    }}
                                >
                                    Open product demo
                                </Button>
                            </Link>
                        </div>
                    </ButtonWrapper>
                </ContentWrapper>
            </ColumnWrapper>
        </Wrapper>
    );
};

export default Intro;
