import { FC, useState } from 'react';
import styled from 'styled-components';
import { BorderFreeRounded, PaddingWrapper } from '../styles/Wrappers';
import constants, { mmsiFlags } from '../../../shared/constants';

const Border = styled(PaddingWrapper)`
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

const SideBar = styled.div`
    position: absolute;
    top: 50px;
    left: 5px;
    z-index: 1000;
    min-width: 175px;
    width: 20%;
    max-width: 325px;
    label {
        margin-bottom: 12px;
    }
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        display: none;
    }
`;

const MMSIWrapper = styled(PaddingWrapper)`
    color: #888;
`;
const IMOWrapper = styled(MMSIWrapper)`
    padding-top: 0;
`;

const ExplanationWrapper = styled(PaddingWrapper)`
    display: flex;
    align-items: space-between;
    justify-content: space-around;
`;

const ImageExplanation = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 20px;
`;

const PathExplanation = styled(ImageExplanation)`
    margin-top: 30px;
`;

const Titular = styled.div`
    font-weight: 600;
    margin-top: 10px;
`;

const SymbolVisualization = styled.span`
    display: flex;
    margin-top: 25px;
    font-size: 18px;
`;

const ColorBall = styled.div<{ $backgroundColor?: string | null }>`
    height: 16px;
    width: 16px;
    border-radius: 17px;
    background-color: ${(props) => (props.$backgroundColor ? props.$backgroundColor : '#ecda80')};
    align-self: center;
    text-align: center;
    margin-right: 10px;
`;

export const QuestionBall = styled.div`
    height: 16px;
    width: 16px;
    border-radius: 17px;
    background-color: #666666;
    align-self: center;
    padding: 2px;
    font-size: 15px;
    text-align: center;
    margin-left: 10px;
    line-height: 18px;
`;

const QuestionPointElement = styled.div`
    position: absolute;
    height: 15px;
    width: 15px;
    background-color: #424652;
    transform: rotate(45deg);
    border-radius: 1.5px;
    top: 3px;
    left: 35px;
    z-index: 1;
`;

const RelativeElement = styled.div`
    position: relative;
`;

const QuestionAnswer = styled.div<{ $setHeight?: number | null }>`
    position: absolute;
    min-width: ${(props) => (props.$setHeight ? props.$setHeight.toString() + 'px' : '100px')};
    top: -22.5px;
    padding: 10px;
    left: 40px;
    border-radius: 2px;
    background-color: #424652;
    z-index: 2;
`;

const PurpleLine = styled.div`
    width: 30px;
    background-color: #9072b4;
    align-self: center;
    padding: 1.5px;
    margin-right: 10px;
`;

const DottedOrangeLine = styled.div`
    width: 30px;
    background-color: ${constants.colors.pallet.lightBlack};
    border-top: 3px dashed #deab75;
    align-self: center;
    margin-right: 10px;
`;

const DottedRedLine = styled.div`
    width: 30px;
    background-color: ${constants.colors.pallet.lightBlack};
    border-top: 3px dashed #ec8e8f;
    align-self: center;
    margin-right: 10px;
`;
const BlueLine = styled.div`
    width: 26px;
    height: 8px;
    background-color: #a762a8;
    align-self: center;
    padding: 1.5px;
    margin-right: 10px;
    border-radius: 5px;
`;

const GreyLine = styled.div`
    width: 30px;
    background-color: #777777;
    align-self: center;
    padding: 1.5px;
    margin-right: 10px;
`;

export const PathfinderLeftSidebar: FC<{
    shipName: string | null;
    mmsi: string;
    shipImo: string | null;
    fromDate: string;
    toDate: string;
    havePlanet: boolean;
    hasOilPipelines: boolean;
}> = ({ shipName, mmsi, shipImo, fromDate, toDate, havePlanet, hasOilPipelines }) => {
    const [hoveredQuestionIndex, setHoveredQuestionIndex] = useState<number>(-1);

    return (
        <SideBar>
            {shipName ? ( // Turn to true when we have ship name in the db
                <BorderFreeRounded>
                    <Border>
                        <h2>
                            {mmsiFlags[mmsi.substring(0, 3)]} {shipName}
                        </h2>
                    </Border>
                    <MMSIWrapper>MMSI: {mmsi}</MMSIWrapper>
                    {shipImo ? <IMOWrapper>IMO: {shipImo}</IMOWrapper> : null}
                </BorderFreeRounded>
            ) : (
                <BorderFreeRounded>
                    <Border>
                        <h2>MMSI: {mmsi}</h2>
                    </Border>
                </BorderFreeRounded>
            )}
            <BorderFreeRounded>
                <Border>
                    {fromDate} - {toDate}
                </Border>
                <ExplanationWrapper>
                    <ImageExplanation>
                        <Titular>Satellite Observations</Titular>
                        <SymbolVisualization>
                            <ColorBall $backgroundColor={'#ecda80'} />
                            Optical Image
                            <QuestionBall
                                onMouseEnter={() => {
                                    setHoveredQuestionIndex(0);
                                }}
                                onMouseLeave={() => {
                                    setHoveredQuestionIndex(-1);
                                }}
                            >
                                ?
                            </QuestionBall>
                            {hoveredQuestionIndex === 0 ? (
                                <RelativeElement>
                                    <QuestionPointElement />
                                    <QuestionAnswer $setHeight={300}>
                                        Medium resolution optical capture overlapping with AIS track. Source: Sentinel-2
                                    </QuestionAnswer>
                                </RelativeElement>
                            ) : null}
                        </SymbolVisualization>
                        <SymbolVisualization>
                            <ColorBall $backgroundColor={'grey'} />
                            SAR Image
                            <QuestionBall
                                onMouseEnter={() => {
                                    setHoveredQuestionIndex(1);
                                }}
                                onMouseLeave={() => {
                                    setHoveredQuestionIndex(-1);
                                }}
                            >
                                ?
                            </QuestionBall>
                            {hoveredQuestionIndex === 1 ? (
                                <RelativeElement>
                                    <QuestionPointElement />
                                    <QuestionAnswer $setHeight={300}>
                                        Syntethic Aperture Radar (SAR) capture overlapping with AIS track. Source:
                                        Sentinel-1
                                    </QuestionAnswer>
                                </RelativeElement>
                            ) : null}
                        </SymbolVisualization>
                        {havePlanet ? (
                            <SymbolVisualization>
                                <ColorBall $backgroundColor={'#00A1FF'} />
                                Planet Image
                                <QuestionBall
                                    onMouseEnter={() => {
                                        setHoveredQuestionIndex(2);
                                    }}
                                    onMouseLeave={() => {
                                        setHoveredQuestionIndex(-1);
                                    }}
                                >
                                    ?
                                </QuestionBall>
                                {hoveredQuestionIndex === 2 ? (
                                    <RelativeElement>
                                        <QuestionPointElement />
                                        <QuestionAnswer $setHeight={300}>
                                            High Resolution images of local areas. This has to be purchased to view.
                                            Click the image to view a low-resolution preview. Source: Planet
                                        </QuestionAnswer>
                                    </RelativeElement>
                                ) : null}
                            </SymbolVisualization>
                        ) : null}
                    </ImageExplanation>
                    <PathExplanation>
                        <Titular>Ship Tracks </Titular>
                        <SymbolVisualization>
                            <PurpleLine />
                            <div style={{ whiteSpace: 'nowrap', alignSelf: 'center' }}>AIS Track</div>
                            <QuestionBall
                                onMouseEnter={() => {
                                    setHoveredQuestionIndex(3);
                                }}
                                onMouseLeave={() => {
                                    setHoveredQuestionIndex(-1);
                                }}
                            >
                                ?
                            </QuestionBall>
                            {hoveredQuestionIndex === 3 ? (
                                <RelativeElement>
                                    <QuestionPointElement />
                                    <QuestionAnswer $setHeight={300}>The continuous AIS signal</QuestionAnswer>
                                </RelativeElement>
                            ) : null}
                        </SymbolVisualization>
                        <SymbolVisualization>
                            <DottedOrangeLine />
                            Gap in AIS
                            <QuestionBall
                                onMouseEnter={() => {
                                    setHoveredQuestionIndex(4);
                                }}
                                onMouseLeave={() => {
                                    setHoveredQuestionIndex(-1);
                                }}
                            >
                                ?
                            </QuestionBall>
                            {hoveredQuestionIndex === 4 ? (
                                <RelativeElement>
                                    <QuestionPointElement />
                                    <QuestionAnswer $setHeight={300}>
                                        Gap between AIS signals longer than 30 minutes. Can be caused by loss of
                                        coverage or vessels deliberately going dark.
                                    </QuestionAnswer>
                                </RelativeElement>
                            ) : null}
                        </SymbolVisualization>
                        <SymbolVisualization>
                            <DottedRedLine />
                            Gap in AIS with Image Coverage
                            <QuestionBall
                                onMouseEnter={() => {
                                    setHoveredQuestionIndex(5);
                                }}
                                onMouseLeave={() => {
                                    setHoveredQuestionIndex(-1);
                                }}
                            >
                                ?
                            </QuestionBall>
                            {hoveredQuestionIndex === 5 ? (
                                <RelativeElement>
                                    <QuestionPointElement />
                                    <QuestionAnswer $setHeight={300}>
                                        AIS gap with satelite image pass, enabling detection of dark vessel.
                                    </QuestionAnswer>
                                </RelativeElement>
                            ) : null}
                        </SymbolVisualization>
                        {hasOilPipelines ? (
                            <SymbolVisualization>
                                <GreyLine />
                                Critical Offshore Pipelines
                                <QuestionBall
                                    onMouseEnter={() => {
                                        setHoveredQuestionIndex(-3);
                                    }}
                                    onMouseLeave={() => {
                                        setHoveredQuestionIndex(-1);
                                    }}
                                >
                                    ?
                                </QuestionBall>
                                {hoveredQuestionIndex === -3 ? (
                                    <RelativeElement>
                                        <QuestionPointElement />
                                        <QuestionAnswer $setHeight={300}>
                                            Exposed valuable infrastructure.
                                        </QuestionAnswer>
                                    </RelativeElement>
                                ) : null}
                            </SymbolVisualization>
                        ) : null}
                        <SymbolVisualization>
                            <BlueLine />
                            <div>Gap Possibility Area </div>
                            <QuestionBall
                                onMouseEnter={() => {
                                    setHoveredQuestionIndex(6);
                                }}
                                onMouseLeave={() => {
                                    setHoveredQuestionIndex(-1);
                                }}
                            >
                                ?
                            </QuestionBall>
                            {hoveredQuestionIndex === 6 ? (
                                <RelativeElement>
                                    <QuestionPointElement />
                                    <QuestionAnswer $setHeight={300}>
                                        Maximum reach of the vessel assuming a max speed and the duration of AIS gap.
                                    </QuestionAnswer>
                                </RelativeElement>
                            ) : null}
                        </SymbolVisualization>
                    </PathExplanation>
                </ExplanationWrapper>
            </BorderFreeRounded>
        </SideBar>
    );
};
