import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { useAuth } from './AuthProvider';

// Reports not requiring login
// Remember to add this whitelisting to both landing page and API.
export const whiteListedReports = [
    '477722100.0_2022-10-29T00:13:37_2023-01-26T16:24:27_v3_linear',
    '737e0ce7-e93f-45f9-8dfa-cbd2b06360f6',
    '92a8e348-bbb9-4330-937a-3f528009f913', // Demo Papua New Guinea 2mnd
    '511101428_2024-05-31T22:00:00+00:00_2024-09-30T22:00:00+00:00', // Asya Energy
];

export const ProtectedContent: React.FC = () => {
    const { token } = useAuth();
    const location = useLocation();
    const params = useParams();
    const isWhitelisted: boolean = params.id !== undefined && whiteListedReports.includes(params.id);
    if (!(isWhitelisted || token)) {
        // user is not authenticated and report is not whitelisted
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    return <Outlet />;
};
