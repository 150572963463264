import styled from 'styled-components';
import { Button } from '../styles/Buttons';
import { FC } from 'react';

const BorderButton = styled(Button)<{ borderColor?: string }>`
    background-color: #4d5354;
    border: 3px solid;
    border-color: ${(props) => (props.borderColor ? props.borderColor : '#4d5354')};
`;
const chooseDrawButtonColor = (isFinished: boolean, isDrawMode: boolean) => {
    if (isFinished) {
        return '#119810'; //green
    } else if (isDrawMode) {
        return '#e0a501'; //yellow
    } else return '#4d5354'; //grey;
};

const chooseDrawButtonText = (isFinished: boolean, isDrawMode: boolean) => {
    if (isFinished) {
        return 'Drawing finished, click to redraw';
    } else if (isDrawMode) {
        return 'Drawing in progress';
    } else return 'Click to draw Area of Interest';
};

type DrawButtonProps = {
    isFinished: boolean;
    isDrawMode: boolean;
    onClick: () => void;
};

export const DrawButton: FC<DrawButtonProps> = ({ isFinished, isDrawMode, onClick }) => {
    const buttonText = chooseDrawButtonText(isFinished, isDrawMode);
    const buttonBorder = chooseDrawButtonColor(isFinished, isDrawMode);

    return (
        <BorderButton onClick={onClick} borderColor={buttonBorder}>
            {buttonText}
        </BorderButton>
    );
};
