import { FC, useEffect } from 'react';
import styled from 'styled-components';

import Why from './why/Why';
import How from './how/how';
import What from './what/What';
import constants from '../../shared/constants';

const Wrapper = styled.div`
    background-image: url(/images/Background.png);
    background-repeat: no-repeat;
    background-position: top center;
    margin: auto;
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        background: none;
    }
`;

const Home: FC = () => {
    useEffect(() => {
        document.title = 'Vake';
    }, []);
    return (
        <Wrapper>
            <Why />
            <How />
            <What />
        </Wrapper>
    );
};

export default Home;
