import { FC } from 'react';
import styled from 'styled-components';
import { Button } from '../../../shared/styles/Buttons';
import { SectionIntro } from '../../../shared/styles/TextFields';
import { Newsletter } from '../../../shared/components/Newsletter';

import constants from '../../../shared/constants';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
    display: flex;
    max-width: calc(100% - 50px);
    width: ${constants.sizes.container};
    margin: auto;
    flex-direction: column;
`;
const AboutItem = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(33% - 20px);
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        margin: 0;
        margin-top: 30px;
        width: calc(100% - 20px);
    }
`;
const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 42px;
`;
const FlexWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        flex-direction: column;
    }
`;
const ButtonWrapper = styled(FlexWrapper)`
    justify-content: center;
    margin: 100px auto;
    button {
        margin-right: 15px;
    }
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        flex-direction: row;
    }
`;
const about = [
    {
        icon: 'images/icons/Multispectral.png',
        heading: 'Multispectral',
        description:
            'We deliver automated vessel detections in multispectral satellite imagery. These images, processed by our algorithm, exploit information throughout the light spectrum, uncovering information no current system can.',
    },
    {
        icon: 'images/icons/Specialized.png',
        heading: 'Specialized',
        description:
            'As opposed to other satellite data analytics solutions, we’re deeply specialised in Maritime Domain analysis. Our solution is proven to efficiently, affordably and automatically detect vessels in any area of interest.',
    },
    {
        icon: 'images/icons/Flexible.png',
        heading: 'Flexible',
        description:
            'We are experts in geospatial data. This makes us able to unlock the real insights of data fusion, and flexible to use the data sources that fit your use case and budget. Our current source results in a price at the fraction of other systems.',
    },
];

const How: FC = () => (
    <>
        <Newsletter
            bottom
            header="See how maritime satellite data impacts business insights and intelligence"
            description="VAKE is continuously improving maritime insights services in close collaboration with governments, offshore asset owners and shipping companies. Sign up to learn more about how AI-boosted satellite data can highlight what is happening at sea, and what this means for your business and use cases.  "
        />
        <Wrapper>
            <h1>Technology</h1>
            <SectionIntro>
                We use our cutting-edge machine learning algorithms to detect ships in satellites images, which provides
                the focal points needed for your maritime operations.
            </SectionIntro>
            <FlexWrapper>
                {about.map((item, i) => {
                    let aboutKey = `about_${i}`;
                    return (
                        <AboutItem key={aboutKey}>
                            <ImageWrapper>
                                <img src={item.icon} alt={`${item.heading}`} />
                            </ImageWrapper>
                            <h2>{item.heading}</h2>
                            <span>{item.description}</span>
                        </AboutItem>
                    );
                })}
            </FlexWrapper>

            <ButtonWrapper>
                <Link to="/report-builder">
                    <Button
                        background={constants.colors.pallet.blue}
                        onClick={() => {
                            (window as any).sa_event(
                                () => 'req_det_open_form_about_on_' + window.document.location.search
                            );
                        }}
                    >
                        Try a free report
                    </Button>
                </Link>
                <Link to="/report">
                    <Button
                        onClick={() =>
                            (window as any).sa_event(() => 'see_test_report_on' + window.document.location.search)
                        }
                    >
                        Look at a sample
                    </Button>
                </Link>
            </ButtonWrapper>
        </Wrapper>
    </>
);

export default How;
