import { FC } from 'react';
import styled from 'styled-components';
import constants from '../../../shared/constants';
import { SectionIntro } from '../../../shared/styles/TextFields';
import { Link } from 'react-router-dom';
import { Button, ButtonLink } from '../../../shared/styles/Buttons';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: ${constants.sizes.container};
    max-width: calc(100% - 50px);
    margin: auto;
`;

const Plans = styled.div`
    display: flex;
    justify-content: space-between;
    h2 {
        margin-top: 0px;
    }
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
    }
`;
const Card = styled.div<{ background: string }>`
    padding: 20px;
    display: flex;
    flex-direction: column;
    background: ${(props) => props.background};
    border-radius: 5px;
    width: calc(33% - 60px);
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        width: calc(100% - 40px);
        margin-bottom: 20px;
    }
`;

const Text = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 350px;
    h2 {
        margin-bottom: 10px;
    }
`;
const Price = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    color: rgba(255, 255, 255, 0.7);
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`;
const Spacing = styled.div`
    display: flex;
    justify-content: space-between;
`;
const DeactivatedItem = styled.span`
    color: rgba(255, 255, 255, 0.7);
`;
interface IPlan {
    background: string;
    heading: string;
    description: JSX.Element;
    price: JSX.Element;
    details: JSX.Element;
    report?: boolean;
}

const plans: IPlan[] = [
    {
        background:
            'linear-gradient(142.22deg, rgba(18, 179, 159, 0.2) 11.43%, rgba(255, 122, 0, 0.2) 73.3%, rgba(255, 0, 245, 0.2) 98.7%)',
        heading: 'VAKE Overwatch',
        description: (
            <span>
                Monitoring of <b>specific areas</b> - unlock Maritime Domain Awareness through AI vessel detections and
                AIS-fusion.
            </span>
        ),
        price: (
            <>
                <em>from 500 € / report</em>
                <em>from 5000 € / monthly subscription</em>
            </>
        ),
        details: (
            <>
                <span>✓ Automatic Data Updates</span>
                <span>✓ Global Coverage</span>
                <span>✓ Seven years of analyzed data and counting</span>
                <span>✓ Support for multiple satellite sources</span>
            </>
        ),
        report: true,
    },
    {
        background:
            'linear-gradient(142.22deg, rgba(131,58,180,0.5) 11.43%, rgba(255, 122, 0, 0.2) 73.3%, rgba(253,187,45,0.2) 98.7%)',
        heading: 'VAKE Pathfinder',
        description: (
            <span>
                Monitor the tracks of <b>specific ships</b> - confirm AIS positions and fill in the gaps from periods of
                dark activity, through machine-learning ship detections.
            </span>
        ),
        price: (
            <>
                <em>from 500 € / report</em>
                <em>from 5000 € / monthly subscription</em>
            </>
        ),
        details: (
            <>
                <span>✓ Global Coverage</span>
                <span>✓ Seven years of analyzed data and counting</span>
                <span>✓ Support for multiple satellite sources</span>
            </>
        ),
    },
    {
        background: 'rgba(58, 125, 255, 0.2)',
        heading: 'Custom Solutions',
        description: (
            <span>
                Tailored development or analysis for specific use cases leveraging our unique expertise in Geospatial
                Intelligence and Analysis
            </span>
        ),
        price: (
            <>
                <em>Price on appraisal</em>
                <br />
            </>
        ),
        details: (
            <>
                <span>✓ Custom integration</span>
                <span>✓ One-to-one delivery</span>
                <span>✓ Support for multiple satellite sources</span>
            </>
        ),
    },
];

const Offerings: FC = () => (
    <Wrapper id="offerings">
        <h1>Products</h1>
        <SectionIntro>
            We cater to your needs and offer multiple modes of accessing our insights. Our data can either be integrated
            with your solutions and interfaces through our API, or viewed through VAKE's intelligent web interfaces that
            unlock analysis on another level.
        </SectionIntro>
        <Plans>
            {plans.map((plan, i) => {
                let planKey = `plan_${i}`;
                return <Plan key={planKey} plan={plan} />;
            })}
        </Plans>
    </Wrapper>
);

const Plan: FC<{ plan: IPlan }> = ({ plan }) => {
    return (
        <Card background={plan.background}>
            <Text>
                <h2>{plan.heading}</h2>
                <DeactivatedItem>{plan.description}</DeactivatedItem>
                <Price>{plan.price}</Price>
                <Column>{plan.details}</Column>
            </Text>
            <Spacing>
                {plan.report ? (
                    <>
                        <Link to="/report-builder">
                            <Button
                                background="white"
                                style={{ marginRight: '15px' }}
                                onClick={() => {
                                    (window as any).sa_event(
                                        () => `req_det_open_form_plans_on__${window.document.location.search}`
                                    );
                                }}
                            >
                                Try a free report
                            </Button>
                        </Link>
                        <Link to="/report">
                            <Button
                                onClick={() =>
                                    (window as any).sa_event(
                                        () => `view_report_from_plan_on_${window.document.location.search}`
                                    )
                                }
                            >
                                Look at a sample
                            </Button>
                        </Link>
                    </>
                ) : (
                    <ButtonLink
                        onClick={() =>
                            (window as any).sa_event(() => `book_meeting_on_${window.document.location.search}`)
                        }
                        target="_blank"
                        href="https://calendly.com/vake/vake-intro"
                        background="white"
                    >
                        Request a meeting
                    </ButtonLink>
                )}
            </Spacing>
        </Card>
    );
};

export default Offerings;
