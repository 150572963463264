import { FC, useEffect } from 'react';
import styled from 'styled-components';
import constants from '../shared/constants';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 530px;
    width: calc(100% - 30px);
    margin: auto;
    a {
        color: white;
    }
`;
const Section = styled.div`
    margin-top: 200px;
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        margin-top: 50px;
    }
`;

export const Terms: FC = () => {
    useEffect(() => {
        document.title = 'Privacy policy';
    }, []);
    useEffect(() => window.scrollTo(0, 0), []);
    return (
        <Wrapper>
            <Section>
                <h2>Cookie information</h2>
                <p>
                    Vake.ai utilizes a web analysing tool named{' '}
                    <a href="https://www.hotjar.com/" target="_blank" rel="noopener noreferrer">
                        Hotjar
                    </a>
                    . Hotjar makes us capable of understanding user flow on the website, helping us discover
                    opportunities for improvement. Information collected by Hotjar is anonymized. <br />
                    <br />
                    <a target="_blank" href="https://www.hotjar.com/legal/policies/privacy/" rel="noopener noreferrer">
                        Read more about Hotjar and their Privacy Policy
                    </a>
                </p>
                <b>What Hotjar collects</b>
                <ul>
                    <li>Your screen resolution</li>
                    <li>Your browser and operating system</li>
                    <li>Time and date of your visit</li>
                    <li>Approximate geographical location, based on your IP-adress</li>
                    <li>Your movements on the site</li>
                    <li>Your clicks on the site</li>
                </ul>
                <p>
                    This information helps us improve the flow of information on the site, so that we more efficiently
                    and precisely can communicate our product.
                </p>
            </Section>
            <Section>
                <h2>Newsletter sign-ups and requests for demo reports</h2>
                <p>
                    <b>Personal data we collect, and how it's stored</b>
                    <br />
                    Vake lets you sign up for our occasional newsletter. When you do so, your email, name and company
                    name are saved with our CRM tool Clickup. When requesting a demo report product from us, we save
                    your email, name, company name and request content in our CRM tool Clickup. We save this information
                    in order to organize customers, leads, and our interactions. We might use your information to follow
                    up with you regarding how we can add value to you and your company. We treat your data
                    confidentially. It is not disclosed or sold to any third party unless disclosure is required by law,
                    judicial body or government authorities. Your personal data is stored in secure databases.
                    <br />
                    <br />
                    <a href="https://clickup.com/privacy" target="_blank" rel="noopener noreferrer">
                        Read more about Clickup's privacy and security policies
                    </a>
                    <br />
                    <br />
                    <b>How long we keep your personal data</b>
                    <br />
                    We retain your personal data for a reasonable amount of time from the date you last expressed
                    interest in our content, products or services (also including having an active newsletter
                    subscription with us).
                    <br />
                    <br />
                    You can unsubscribe from our newsletter directly from one of our previous letters sent to you, or by
                    reaching out to support@vake.ai. You can request the deletion of (and a copy of, as well as
                    correction of) all your personal data from our systems at any time through support@vake.ai.
                </p>
            </Section>
        </Wrapper>
    );
};
