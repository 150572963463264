import { FC } from 'react';
import styled from 'styled-components';
import { Button } from '../../styles/Buttons';

const StyledButton = styled(Button)`
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 1);
    border-radius: 2px;
    margin: 0px;
`;

interface Props {
    activeStep: null | number;
    isJourneyMode: boolean;
    storyButtonDisabled: boolean;
    onEnableMap: (is: boolean) => void;
}
const StoryButton: FC<Props> = ({ activeStep, isJourneyMode, storyButtonDisabled = false, onEnableMap }) => {
    return (
        <>
            {activeStep !== null && !storyButtonDisabled && (
                <StyledButton onClick={() => onEnableMap(isJourneyMode)}>
                    {isJourneyMode ? 'Explore map' : 'Continue story'}
                </StyledButton>
            )}
        </>
    );
};

export default StoryButton;
