import { apiBaseUrl } from '../utils/env';

const vake_api_server_url = apiBaseUrl();

export const postContact = (
    name: string,
    email: string,
    companyName: string,
    newsletter: boolean,
    callback: Function
) => {
    const url = `${vake_api_server_url}/crm/contact`;
    const body = JSON.stringify({
        email: email,
        name: name,
        company_name: companyName,
        newsletter: newsletter,
    });
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    fetch(url, {
        method: 'POST',
        body: body,
        headers: headers,
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error('failed to post contact');
            }
        })
        .then(() => {
            callback(true);
        })
        .catch(() => {
            callback(false);
        });
};

export const postReportRequest = (
    name: string,
    email: string,
    companyName: string,
    newsletter: boolean,
    aoi: string,
    timeframe: string,
    callback: Function
) => {
    console.log('starting to request report');
    const url = `${vake_api_server_url}/crm/report_request`;
    const body = JSON.stringify({
        contact: {
            email: email,
            name: name,
            company_name: companyName,
            newsletter: newsletter,
        },
        area_of_interest: aoi,
        timeframe: timeframe,
    });
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    fetch(url, {
        method: 'POST',
        body: body,
        headers: headers,
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Failed to post report request');
            }
        })
        .then(() => {
            callback(true);
        })
        .catch((error) => {
            console.log(error);
            callback(false);
        });
};
