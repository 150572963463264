import { FC } from 'react';
import styled from 'styled-components';
import constants from '../../../shared/constants';
import Partnerships from './Partnerships';
import { Newsletter } from '../../../shared/components/Newsletter';
import { FAQ } from './FAQ';
import Offerings from './Offerings';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    // max-width: ${constants.sizes.container};
    margin: auto;
    width: 100%;
`;

const What: FC = () => (
    <>
        <Wrapper>
            <Offerings />
            <FAQ />
            <Newsletter
                header="Want to learn more about satellite data?"
                description="Satellite data is the next generation of maritime insights. Learn about the possibilities and get ahead of the curve. Sign up for our email introduction into the word of machine learning and maritime satellite insight."
            />
            <Partnerships />
        </Wrapper>
    </>
);

export default What;
