import styled from 'styled-components';
import constants from '../constants';
// import constants from "../../constants";

export const InputField = styled.input<{ error?: boolean }>`
    width: 100%;
    padding: 12px 16px;
    margin: 8px 0px;
    box-sizing: border-box;
    border-radius: 5px;
    background: livid;
    border: 2px solid rgba(0, 0, 0, 0.4);
    border-color: ${(props) => (props.error ? constants.colors.pallet.error : 'rgba(0, 0, 0, 0.5)')};
    border-width: ${(props) => (props.error ? '0.5px' : '2px')};
    font-size: 16px;
    line-height: 24px;
    color: black;
    isolation: isolate;
    &:focus {
        outline: none;
    }

    &::placeholder {
        color: black;
    }
`;
