import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { ControlPosition, useControl } from 'react-map-gl';

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
    position?: ControlPosition;

    onCreate?: (evt: { features: object[] }) => void;
    onUpdate?: (evt: { features: object[]; action: string }) => void;
    onDelete?: (evt: { features: object[] }) => void;
};

function DrawControl(props: DrawControlProps) {
    useControl(
        () => new MapboxDraw(props),
        ({ map }) => {
            // Setup event listeners
            map.on('draw.create', props.onCreate || (() => {}));
        },
        ({ map }) => {
            // Shut down event listeners
            map.off('draw.create', props.onCreate || (() => {}));
        },
        {
            position: props.position,
        }
    );

    return null;
}

export default DrawControl;
