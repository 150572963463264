import { useState, useEffect } from 'react';

function getStorageValue<T>(key: string, defaultValue: T): T {
    // getting stored value
    if (typeof window !== 'undefined') {
        const saved = localStorage.getItem(key);
        const initial = saved !== null ? JSON.parse(saved) : defaultValue;
        return initial;
    }
    return defaultValue;
}

export const useLocalStorage = <T>(
    key: string,
    defaultValue: T
): [T, React.Dispatch<React.SetStateAction<T>>, () => void] => {
    const [value, setValue] = useState<T>(() => {
        return getStorageValue(key, defaultValue);
    });

    useEffect(() => {
        // storing input name
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    const remove = () => {
        if (typeof window !== 'undefined') {
            localStorage.removeItem(key);
            setValue(defaultValue); // Reset state to default after removal
        }
    };

    return [value, setValue, remove];
};
