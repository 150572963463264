import React from 'react';
import styled from 'styled-components';
import constants from '../shared/constants';
import { PageContentWrapper } from '../shared/styles/wrappers';
import { Newsletter } from '../shared/components/Newsletter';

const Header = styled.div`
    margin-bottom: 10px;
`;

const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(80%);
    img {
        margin: auto auto 30px auto;
        max-width: 100%;
    }
    @media screen and (max-width: ${constants.breakpoints.miniTablet}) {
        width: 100%;
        img {
            /* margin-bottom: 30px; */
            width: 100%;
        }
    }
`;

const PressReleasePage: React.FC = () => {
    return (
        <PageContentWrapper>
            <Header>
                <p>PRESS RELEASE</p>
                <h1>Kongsberg Satellite Services acquires a majority stake in space startup VAKE</h1>
                <p>20. mars, 2024</p>
            </Header>
            <ImageWrapper>
                <img src="./images/vake_gruppebilde.jpg" alt="gruppebilde_vake" />
            </ImageWrapper>
            <p>
                The Norwegian company VAKE aims to become a world leader in machine learning and data fusion of
                satellite sources and data to deliver maritime insights that help prevent inefficient and illegal
                exploitation of the ocean. Since 2018, they have been working on applying ML/AI in satellite data to
                detect and analyze the behavior of ships, including ships without location trackers.
            </p>
            <p>
                The latest in the series of capabilities is to use ML and AI to identify ships that choose to hide their
                activity at sea. This is exemplified through the collaboration with Finland's largest newspaper,
                Helsingin Sanomat, where VAKE helped confirm Admiral Vladimirsky's movements around the BalticConnector
                pipeline amid suspicions of sabotage (
                <a href="https://www-hs-fi.translate.goog/kotimaa/art-2000009943838.html?_x_tr_sl=auto&_x_tr_tl=en&_x_tr_hl=en&_x_tr_pto=wapp&_x_tr_hist=true">
                    link
                </a>
                ).
            </p>
            <p>
                Such capabilities are sought after by several segments and industries and are also of interest to
                authorities.{' '}
                <i>
                    "We see particularly great interest for our products and services in sustainability efforts for
                    illegal fishing and oil spill detections, as well as in shipping, security for offshore
                    infrastructure after the NordStream sabotage, and in the defense segment after the full-scale
                    invasion of Ukraine in 2022,"
                </i>{' '}
                says Tugba Gunes, Chief Commercial Officer at VAKE.
            </p>
            <p>
                VAKE and KSAT consider the use of AI and ML to analyze satellite images as essential for the future of
                Earth observation services.
                <i>
                    "In the next decade, it is expected that there will be up to a tenfold increase in the number of
                    satellites. The data potential is almost infinite, and it will therefore be natural for AI/ML to
                    play a larger role in assisting operators and analysts in their work. This will increase
                    understanding and provide greater insights into actual events based on satellite data,"
                </i>{' '}
                says Thomas Leira, CEO of VAKE.
            </p>
            <p>
                <i>
                    "In addition to the work we are doing with government agencies we experience also that there has
                    been an increasing interest for our technology and services from the industry. The contract and the
                    work we have been doing with Offshore Energy company Equinor has in this regard been especially
                    important for our focus and for the development of our company,"
                </i>{' '}
                says Leira.
            </p>
            <h3>KSAT as strategic owner</h3>
            <p>
                Since the inception of the company, KSAT has been following the development of VAKE and has over time
                seen the positive developments both in technology and customer interest.{' '}
                <i>
                    "We see this as a fantastic opportunity where KSATs solid experience, extensive market knowledge and
                    position combined with VAKE's capacities in AI/ML and innovative approach will result in improved
                    services, increased market share in existing markets as well as opportunities in adjacent markets,"
                </i>{' '}
                says KSAT Executive Vice President Strategy and Technology, Arnulf Kjeldsen.
            </p>
            <p>
                While KSAT holds majority ownership, VAKE remains an independent company with the same team, continuing
                to provide its offerings to the market, but now with the possibility of being integrated into KSAT's
                deliveries as well.
            </p>
            <h2>Strategic growth decision</h2>
            <p>
                <i>
                    "This was a clear strategic choice for us with the goal of increasing access to satellite sources,
                    reducing the time it takes before satellite images are made available to customers and end users,
                    and as a young company benefiting from the expertise and network that KSAT has built up over decades
                    in the space industry,"
                </i>{' '}
                says Leira.
            </p>
            <p>
                <i>
                    "KSAT is a world-leading provider of satellite data reception with the best global network for
                    downloading and distributing data from satellites. Teaming up with KSAT jointly gives us a
                    competitive advantage and will help in getting to the market positions and opportunities we are
                    targeting internationally much faster,"
                </i>{' '}
                adds Gunes.
            </p>
            <p>
                <b>About VAKE:</b> VAKE was incubated by{' '}
                <a href="https://www.iterate.no/ventures">Iterate Venture Studio</a> in 2019 and has secured investments
                from, among others, Sverre Bisgaard (Norspace), Kjeller Innovasjon, Patrik Berglund (Xeneta), and Space
                Ventures Investors. The team has participated in accelerator programs such as Techstars,{' '}
                <a href="https://www.cassini.eu/accelerator/">Cassini Business Accelerator</a>, and{' '}
                <a href="https://www.esabic.no/">ESA BIC Norway</a>, and has received support from Innovation Norway and
                the Research Council. The company focuses on using AI and ML to merge and analyze satellite data for
                enhanced Maritime Surveillance and situational awareness, with key contracts including Equinor,
                Norwegian Space Agency, the European Space Agency (ESA), as well as other European governmental
                organizations.
            </p>
            <Newsletter
                header="Want to learn more about satellite data?"
                description="Satellite data is the next generation of maritime insights. Learn about the possibilities and get ahead of the curve. Sign up for our email introduction into the word of machine learning and maritime satellite insight."
            />
        </PageContentWrapper>
    );
};

export default PressReleasePage;
