const CONSENT_COOKIE_NAME = 'vake-consent-cookie';

export const getConsentCookie = (): boolean => {
    const cookie = document.cookie.split(';').some((v) => v.trim().includes(CONSENT_COOKIE_NAME));
    return cookie;
};

export const setConsentCookie = (): void => {
    document.cookie = `${CONSENT_COOKIE_NAME}=true;expires=${new Date(Date.now() + 60 * 60 * 24 * 365 * 1000)}`;
};
