export const AddDougaMapLayers = (currMap: mapboxgl.Map) => {
    currMap.loadImage('https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png', (error: any, image: any) => {
        if (error) throw error;
        currMap.addImage('generic_marker', image);
        // Add a GeoJSON source with 2 points for OW and CV for Black Sea
        currMap.addSource('douga_source_black_sea_marker', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        properties: {
                            title: 'OverWatch Black Sea',
                            link: window.location.origin + '/overwatch/51c931b8-f3e6-44ac-8173-8313b4941d19',
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: [31.420825734964975, 43.971494261304336],
                        },
                    },
                    {
                        type: 'Feature',
                        properties: {
                            title: 'ClearView Black Sea',
                            link:
                                window.location.origin +
                                '/clearview?areaCoordinates=%5B%5B%5B28.7256471341897%2C47.11021312591532%5D%2C%5B26.973977879254306%2C40.56315284309889%5D%2C%5B42.81820345513216%2C40.85547971518773%5D%2C%5B39.85174778052735%2C47.636314564781856%5D%2C%5B28.7256471341897%2C47.11021312591532%5D%5D%5D&fromDate=Mon+Apr+17+2023&toDate=Sat+May+06+2023&uuid=%5B%227ee71f56-62e1-43c1-b731-4449e52b8ea5%22%2C%2258e5d4c8-9dcd-4a35-8f7b-576b552bb600%22%2C%2273f4316c-ae4b-4a10-9a41-12d051136bbe%22%2C%22333e70bd-97a5-4aef-a91a-154e47e7d841%22%2C%22fe0c74ea-36bd-4f4a-9415-3545c5204f87%22%5D',
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: [36.05589310034594, 43.94568506602661],
                        },
                    },
                ],
            },
        });
        // Add layer for visualizing black sea markers
        currMap.addLayer({
            id: 'douga_black_sea_marker',
            type: 'symbol',
            source: 'douga_source_black_sea_marker',
            layout: {
                'icon-image': 'generic_marker', // reference the image
                'icon-size': ['interpolate', ['linear'], ['zoom'], 4, 0.35, 7, 1.5],
                'text-field': ['get', 'title'],
                'text-size': ['interpolate', ['linear'], ['zoom'], 4, 4, 7, 20],
                'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                'text-offset': [0, 1],
                'text-anchor': 'top',
            },
            paint: {
                'text-color': '#aaffff',
            },
        });
        // Add aoi around black sea to display where OW and CV show
        currMap
            .addSource('douga_black_sea_map', {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    properties: {
                        title: 'Black Sea Map',
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [28.7256471341897, 47.11021312591532],
                                [26.973977879254306, 40.56315284309889],
                                [42.81820345513216, 40.85547971518773],
                                [39.85174778052735, 47.636314564781856],
                                [28.7256471341897, 47.11021312591532],
                            ],
                        ],
                    },
                },
            })
            .addLayer({
                id: 'black_sea_outline',
                type: 'line',
                source: 'douga_black_sea_map',
                layout: {
                    visibility: 'none',
                },
                paint: {
                    'line-color': '#555',
                    'line-width': 3,
                },
            })
            .addLayer({
                id: 'black_sea_fill',
                type: 'fill',
                source: 'douga_black_sea_map',
                layout: { visibility: 'none' },
                paint: {
                    'fill-color': '#0080ff',
                    'fill-opacity': 0.3,
                },
            });
        // For toggling visibility when hovering relevant markers
        currMap.on('mouseenter', 'douga_black_sea_marker', () => {
            if (!currMap.getLayer('black_sea_outline') || !currMap.getLayer('black_sea_fill')) {
                return;
            }
            const visibility = currMap.getLayoutProperty('black_sea_fill', 'visibility');
            if (visibility === 'none') {
                currMap.setLayoutProperty('black_sea_fill', 'visibility', 'visible');
                currMap.setLayoutProperty('black_sea_outline', 'visibility', 'visible');
            }
        });
        currMap.on('mouseleave', 'douga_black_sea_marker', () => {
            if (!currMap.getLayer('black_sea_outline') || !currMap.getLayer('black_sea_fill')) {
                return;
            }
            const visibility = currMap.getLayoutProperty('black_sea_fill', 'visibility');
            if (visibility === 'visible') {
                currMap.setLayoutProperty('black_sea_fill', 'visibility', 'none');
                currMap.setLayoutProperty('black_sea_outline', 'visibility', 'none');
            }
        });
        // Opens links from relevant marker
        currMap.on('click', 'douga_black_sea_marker', (e) => {
            if (e.features && e.features.length) {
                window.open(e.features[0].properties?.link, '_blank');
            }
        });
        // Korea Part of code, is structured exactly as black sea code
        currMap.addSource('douga_source_korea_marker', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        properties: {
                            title: 'ClearView Korea-Japan Sea',
                            link:
                                window.location.origin +
                                '/clearview?areaCoordinates=%5B%5B%5B130.9871542439114%2C34.05966063000852%5D%2C%5B129.19510173277536%2C35.45090881996187%5D%2C%5B126.92209463794836%2C40.14271701349685%5D%2C%5B131.99622012136314%2C43.58474050442129%5D%2C%5B139.44549151050342%2C48.138837803877834%5D%2C%5B142.6608551349957%2C47.41097118103144%5D%2C%5B141.94507087408283%2C43.308976778984686%5D%2C%5B140.45143699192283%2C40.04199021754431%5D%2C%5B139.65582927668163%2C37.91184625093615%5D%2C%5B136.0172563310142%2C35.354666375816294%5D%2C%5B130.9871542439114%2C34.05966063000852%5D%5D%5D&fromDate=Thu+Jul+06+2023&toDate=Thu+Jul+20+2023&uuid=%5B%227ee71f56-62e1-43c1-b731-4449e52b8ea5%22%2C%2258e5d4c8-9dcd-4a35-8f7b-576b552bb600%22%2C%2273f4316c-ae4b-4a10-9a41-12d051136bbe%22%2C%22333e70bd-97a5-4aef-a91a-154e47e7d841%22%2C%22fe0c74ea-36bd-4f4a-9415-3545c5204f87%22%5D',
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: [133.75524410741713, 38.679299647865314],
                        },
                    },
                    {
                        type: 'Feature',
                        properties: {
                            title: 'OverWatch Korea-Japan Sea',
                            link: window.location.origin + '/overwatch/6f5f086a-575e-49fc-a4d1-e18a7ec21802',
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: [131.42301819610972, 37.40200623570089],
                        },
                    },
                ],
            },
        });
        currMap.addLayer({
            id: 'douga_korea_marker',
            type: 'symbol',
            source: 'douga_source_korea_marker',
            layout: {
                'icon-image': 'generic_marker', // reference the image
                'icon-size': ['interpolate', ['linear'], ['zoom'], 4, 0.35, 7, 1.5],
                'text-field': ['get', 'title'],
                'text-size': ['interpolate', ['linear'], ['zoom'], 4, 4, 7, 20],
                'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                'text-offset': [0, 1],
                'text-anchor': 'top',
            },
            paint: {
                'text-color': '#aaffff',
            },
        });

        currMap
            .addSource('douga_korea_map', {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    properties: {
                        title: 'East Korea Map',
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                [130.9871542439114, 34.05966063000852],
                                [129.19510173277536, 35.45090881996187],
                                [126.92209463794836, 40.14271701349685],
                                [131.99622012136314, 43.58474050442129],
                                [139.44549151050342, 48.138837803877834],
                                [142.6608551349957, 47.41097118103144],
                                [141.94507087408283, 43.308976778984686],
                                [140.45143699192283, 40.04199021754431],
                                [139.65582927668163, 37.91184625093615],
                                [136.0172563310142, 35.354666375816294],
                                [130.9871542439114, 34.05966063000852],
                            ],
                        ],
                    },
                },
            })
            .addLayer({
                id: 'east_korea_outline',
                type: 'line',
                source: 'douga_korea_map',
                layout: {
                    visibility: 'none',
                },
                paint: {
                    'line-color': '#555',
                    'line-width': 3,
                },
            })
            .addLayer({
                id: 'east_korea_fill',
                type: 'fill',
                source: 'douga_korea_map', // reference the data source
                layout: { visibility: 'none' },
                paint: {
                    'fill-color': '#0080ff', // blue color fill
                    'fill-opacity': 0.3,
                },
            });

        // For toggling visibility when hovering relevant markers
        currMap.on('mouseenter', 'douga_korea_marker', () => {
            if (!currMap.getLayer('east_korea_outline') || !currMap.getLayer('east_korea_fill')) {
                return;
            }
            const visibility = currMap.getLayoutProperty('east_korea_fill', 'visibility');
            if (visibility === 'none') {
                currMap.setLayoutProperty('east_korea_fill', 'visibility', 'visible');
                currMap.setLayoutProperty('east_korea_outline', 'visibility', 'visible');
            }
        });
        currMap.on('mouseleave', 'douga_korea_marker', () => {
            if (!currMap.getLayer('east_korea_outline') || !currMap.getLayer('east_korea_fill')) {
                return;
            }
            const visibility = currMap.getLayoutProperty('east_korea_fill', 'visibility');
            if (visibility === 'visible') {
                currMap.setLayoutProperty('east_korea_fill', 'visibility', 'none');
                currMap.setLayoutProperty('east_korea_outline', 'visibility', 'none');
            }
        });

        // Opens links from relevant marker
        currMap.on('click', 'douga_korea_marker', (e) => {
            if (e.features && e.features.length) {
                window.open(e.features[0].properties?.link, '_blank');
            }
        });
    });
};
