import { useQuery } from 'react-query';
import { getRequestHeaders, vake_api_server_url } from '../requests/helpers';
import { useAuth } from '../auth/AuthProvider';

interface SearchResult {
    name: string;
    mmsi: string;
    imo: string;
}

export const useVesselNameSearch = (searchInput: string) => {
    const { token } = useAuth();
    const url = `${vake_api_server_url}/search?query=${searchInput}`;
    return useQuery<SearchResult[]>(
        ['search', searchInput],
        async () => {
            if (searchInput === '') {
                return [];
            }
            const res = await fetch(url, {
                credentials: 'include',
                headers: getRequestHeaders(token),
            });
            if (!res.ok) {
                throw new Error(res.statusText);
            }
            return await res.json();
        },
        { enabled: !!token }
    );
};
