import { FC, useEffect, useRef, ReactElement, useContext, useCallback } from 'react';
import styled from 'styled-components';
import { Chapter } from './types/data';
import { AppContext } from './Storyteller';

const StoryWrapper = styled.div`
    position: absolute;
    overflow: auto;
    margin: 0px;
    z-index: 1100;
`;

interface Props {
    chapters: any;
    activeStep: number;
    children: ReactElement[];
    topOffset?: number;
    onChangeStep: (step: number, chapterAfter: Chapter, chapterBefore?: Chapter) => void;
    currentScrollTop: number;
    setCurrentScrollTop: any;
    debug?: boolean;
}
const Scroll: FC<Props> = ({
    chapters,
    activeStep,
    children,
    topOffset = 100,
    onChangeStep,
    currentScrollTop,
    setCurrentScrollTop,
}) => {
    const {
        state: { ranges },
    } = useContext(AppContext);
    const wrapperRef = useRef(null);

    const addToRanges = useCallback(
        (el: HTMLDivElement | null) => {
            if (el && ranges.length < chapters.length) {
                const range = (ranges[ranges.length - 1] || 0) + el.scrollHeight;
                ranges.push(range);
            }
        },
        [ranges, chapters]
    );

    const HOCCopy = (props: any, children: ReactElement, key: number) => {
        return (
            <div
                position={'relative'}
                ref={(el: HTMLDivElement | null) => addToRanges(el)}
                {...props}
                key={`scroll-item-${key}`}
            >
                {children}
            </div>
        );
    };

    useEffect(
        () => {
            const newActiveStep = ranges.findIndex((range) => currentScrollTop <= range - topOffset);
            if (newActiveStep !== activeStep && newActiveStep > -1) {
                onChangeStep(newActiveStep, chapters[newActiveStep], chapters[activeStep]);
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentScrollTop]
    );

    useEffect(
        () => {
            if (activeStep !== 0) window.scrollTo({ top: ranges[activeStep - 1] });
            onChangeStep(activeStep, chapters[activeStep]);
            window.addEventListener('scroll', (e: Event) => {
                window.requestAnimationFrame(() => {
                    setCurrentScrollTop((e.target as any)?.scrollingElement.scrollTop.toFixed());
                });
            });
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <StoryWrapper ref={wrapperRef} key={activeStep}>
            {children.map((child, i) => HOCCopy(child.props, child.props.children, i))}
        </StoryWrapper>
    );
};

export default Scroll;
