import { FC, useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ boxWidth: number }>`
    background-color: #7c8fb4;
    opacity: 0.8;
    border-radius: 2px;
    width: ${(props) => props.boxWidth}px;
    padding: 0.8px;
`;

const PercentCounterWrapper = styled.div<{ boxWidth: number }>`
    display: flex;
    width: ${(props) => props.boxWidth}px;
    align-items: center;
    justify-content: flex-end;
    background-color: #34486f;
    border-radius: 2px;
    min-width: 26px;
    transition: 0.6s all linear;
`;

const PercentCounter = styled.div`
    color: #fff;
    font-weight: 300;
    font-size: 12px;
`;

interface Props {
    step: number;
    totalSteps: number;
}
const ProgressBar: FC<Props> = ({ step, totalSteps }) => {
    const boxWidth = 200;
    useEffect(() => {}, [step, totalSteps]);

    return (
        <Wrapper boxWidth={boxWidth}>
            <PercentCounterWrapper boxWidth={(boxWidth * step) / totalSteps}>
                <PercentCounter>{`${((step / totalSteps) * 100).toFixed()}%`}</PercentCounter>
            </PercentCounterWrapper>
        </Wrapper>
    );
};

export default ProgressBar;
