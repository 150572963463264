import { FC } from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;
const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    height: 100%;
`;
const SpinnerImg = styled.img`
    width: 35px;
    height: 35px;
    animation: ${spin} 0.8s linear infinite;
`;
export const Spinner: FC = () => {
    return (
        <SpinnerWrapper>
            <SpinnerImg src="/images/icons/Spinner.png" />
        </SpinnerWrapper>
    );
};
