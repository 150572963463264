import { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 40%;
`;

interface Props {
    title?: string;
    description: string[];
}
const IntroductionChapter: FC<Props> = ({ title, description }) => (
    <Wrapper>
        <h1 style={{ color: '#FFFFFF' }}>{title}</h1>
        <h2 style={{ color: '#FFFFFF' }}>
            <i>{description}</i>
        </h2>
    </Wrapper>
);

export default IntroductionChapter;
