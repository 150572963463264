import { FC } from 'react';
import styled from 'styled-components';
import { BorderFreeRounded } from '../pages/Report/styles/Wrappers';

const Wrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
`;
const Shader = styled(Wrapper)`
    z-index: 100;
    background-color: rgba(30, 31, 85, 0.5);
`;

const CenteredErrorMessage = styled(BorderFreeRounded)`
    position: absolute;
    top: 40%;
    left: 40%;
    width: 30vw;
    padding: 2vw;
    text-align: center;
    align-items: center;
`;

export const PathfinderErrorHandler: FC = () => {
    return (
        <Shader>
            <CenteredErrorMessage>
                No data was found for this report.
                <div style={{ width: '20%', maxWidth: '45px' }}>
                    <img width={'100%'} src="/images/icons/satellite.svg" alt="no source" />
                </div>
                It can take a few minutes to generate. <br />
                However, if it still does not appear, send us an email and we will be happy for the feedback.
            </CenteredErrorMessage>
        </Shader>
    );
};

export const ClearviewErrorHandler: FC = () => {
    return (
        <Shader>
            <CenteredErrorMessage>
                Your user does not have access to the VAKE tool for locating dark vessels.
                <div style={{ height: '10%', maxWidth: '40px', margin: '2%' }}>
                    <img width={'100%'} src="/images/icons/cross_white.svg" alt="no source" />
                </div>
                Contact VAKE for a trial.
            </CenteredErrorMessage>
        </Shader>
    );
};
