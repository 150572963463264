export const OverWatchSteps = [
    {
        id: 'intro',
        attachTo: { element: '#report_name', on: 'bottom' as const },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Exit',
                type: 'cancel',
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Back',
                type: 'back',
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Next',
                type: 'next',
            },
        ],
        highlightClass: 'highlight',
        scrollTo: false,
        cancelIcon: {
            enabled: true,
        },
        title: 'Welcome to VAKE Overwatch',
        text: [
            'This is a quick tutorial to familiarize yourself with the features of VAKE Overwatch',
            ' every unique report has a name, in this case the English Channel.',
        ],
    },
    // {
    //     id: '2',
    //     attachTo: { element: '#admin-button', on: 'bottom' as const },
    //     buttons: [
    //         {
    //             classes: 'shepherd-button-secondary',
    //             text: 'Exit',
    //             type: 'cancel',
    //         },
    //         {
    //             classes: 'shepherd-button-primary',
    //             text: 'Back',
    //             type: 'back',
    //         },
    //         {
    //             classes: 'shepherd-button-primary',
    //             text: 'Next',
    //             type: 'next',
    //         },
    //     ],
    //     highlightClass: 'highlight',
    //     scrollTo: false,
    //     cancelIcon: {
    //         enabled: true,
    //     },
    //     title: 'Overview of all reports',
    //     text: ['You can go to the Admin panel to get an overview of all your unique Overwatch reports'],
    // },
    {
        id: '3',
        attachTo: { element: '#date_selector', on: 'bottom' as const },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Exit',
                type: 'cancel',
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Back',
                type: 'back',
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Next',
                type: 'next',
            },
        ],
        highlightClass: 'highlight',
        scrollTo: false,
        cancelIcon: {
            enabled: true,
        },
        title: 'View available dates',
        text: [
            'Each Overwatch Report is defined with a start date and an end date, within this time-range, all available satellite imagery is analyzed. Use this menu to navigate through the available dates',
        ],
    },
    {
        id: '4',
        attachTo: { element: '#mmsi_search', on: 'bottom' as const },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Exit',
                type: 'cancel',
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Back',
                type: 'back',
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Next',
                type: 'next',
            },
        ],
        highlightClass: 'highlight',
        scrollTo: false,
        cancelIcon: {
            enabled: true,
        },
        title: 'MMSI Search',
        text: [
            'For faster navigation, you can search for specific MMSI numbers here. The matching ships for the selected day will show up.',
        ],
    },
    {
        id: '5',
        attachTo: { element: '#current_date', on: 'bottom' as const },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Exit',
                type: 'cancel',
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Back',
                type: 'back',
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Next',
                type: 'next',
            },
        ],
        highlightClass: 'highlight',
        scrollTo: false,
        cancelIcon: {
            enabled: true,
        },
        title: 'Current Day',
        text: ['Each page of the report currently shows one particular day, defaulting to the latest available date.'],
    },
    {
        id: '6',
        attachTo: { element: '#date_navigator', on: 'bottom' as const },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Exit',
                type: 'cancel',
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Back',
                type: 'back',
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Next',
                type: 'next',
            },
        ],
        highlightClass: 'highlight',
        scrollTo: false,
        cancelIcon: {
            enabled: true,
        },
        title: 'Navigation',
        text: ['In addition to the calendar, you can also navigate between dates with these arrows.'],
    },
    {
        id: '6',
        attachTo: { element: '#legend', on: 'bottom' as const },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Exit',
                type: 'cancel',
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Back',
                type: 'back',
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Next',
                type: 'next',
            },
        ],
        highlightClass: 'highlight',
        scrollTo: false,
        cancelIcon: {
            enabled: true,
        },
        title: 'Map Legend',
        text: [
            'This is the map legend, giving you information about the verified AIS messages (green), the detected dark vessels(red), and the uncorrelated AIS.(blue)',
        ],
    },
    {
        id: '7',
        attachTo: { element: '#download_csv', on: 'bottom' as const },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Exit',
                type: 'cancel',
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Back',
                type: 'back',
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Finish',
                type: 'next',
            },
        ],
        highlightClass: 'highlight',
        scrollTo: false,
        cancelIcon: {
            enabled: true,
        },
        title: 'Download data',
        text: [
            'In addition to interacting with the map directly, you can download the raw data for the selected date with this button.',
        ],
    },
];
