import { FC } from 'react';
import styled from 'styled-components';
import constants from '../../../shared/constants';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    color: rgba(255, 255, 255, 0.5);
    margin: auto;
    margin-top: 120px;
    margin-bottom: 120px;
    max-width: ${constants.sizes.container};
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        width: 100%;
    }
`;
const CompanyWrapper = styled.div`
    display: grid;
    grid-template-rows: 200px 200px;
    grid-template-columns: 25% 25% 25% 25%;
    background: rgba(255, 255, 255, 0.5);
    grid-gap: 1px;
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        display: none;
    }
`;

const GridCell = styled.div`
    background: black;
    padding: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;

    div {
        height: 75px;
    }
    img {
        max-width: 120px;
        max-height: 45px;
    }
`;

const MobileCell = styled(GridCell)`
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 2px;
    width: cacl(222px - 40px);
`;

const TextWrapper = styled.span`
    font-size: 13px;
    line-height: 17px;
    a {
        text-decoration: underline;
        color: rgba(255, 255, 255, 0.5);
    }
`;

const ColumnWrapper = styled.div`
    display: none;
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        display: flex;
        flex-direction: column;
        span {
            margin-left: 20px;
        }
    }
`;

const MobileWrapper = styled.div`
    display: none;
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        display: flex;
        justify-content: center;
    }
`;
const TestWrapper = styled.div`
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    display: grid;
    grid-gap: 1rem;
    grid-auto-flow: column;
    grid-auto-columns: 220px;
    grid-auto-rows: 1fr;
`;
let companies = [
    {
        title: 'NATO',
        img: 'images/icons/Recognized/Nato.png',
        text: (
            <>
                Winners of the{' '}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.ncia.nato.int/about-us/newsroom/nci-agency-announces-winners-of-2019-annual-defence-innovation-challenge.html"
                >
                    Innovation Challenge
                </a>{' '}
                at NATOs tech conference in 2019
            </>
        ),
    },
    {
        title: 'ESA',
        img: 'images/icons/Recognized/Esa.png',
        text: (
            <>
                Winners of{' '}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.esa.int/Applications/Observing_the_Earth/Copernicus/Vake_Catch_wins_2019_Space_App_Camp#:~:text=An%20app%20that%20will%20help,observation%20centre%20in%20Frascati%2C%20Italy"
                >
                    ESA app camp 2019
                </a>{' '}
                and part of their Business Incubation Center in Norway
            </>
        ),
    },
    {
        title: 'Airbus',
        img: 'images/icons/Recognized/Airbus.png',
        text: <> Partnership agreement with Airbus’ subsidiary UP42, for access to Airbus imagery</>,
    },
    {
        title: 'Techstars',
        img: 'images/icons/Recognized/Techstars.png',
        text: (
            <>
                Part of{' '}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://demoday.techstars.com/allied-space-2020-t3?c=vake"
                >
                    Techstars Allied Space Accelerator Program
                </a>
                , in partnership with US Air Force, Norwegian and Dutch DoDs
            </>
        ),
    },
    {
        title: 'Socom',
        img: 'images/icons/Recognized/Socom.png',
        text: <>Part of a Rapid Capability Assessment initative run by Liberty Alliance, LLC for delivery to SOCOM</>,
    },
    {
        title: 'Norsk romsenter',
        img: 'images/icons/Recognized/NorskRomsenter.png',
        text: <>Project funding to evaluate onboard processing for Vake’s algorithm</>,
    },
    {
        title: 'AF Werx',
        img: 'images/icons/Recognized/Afwerx.png',
        text: <>Selected by AF Werx Persistent ISR Challenge as a collaborator</>,
    },
    {
        title: 'Nifro',
        img: 'images/icons/Recognized/Nifro.png',
        text: (
            <>
                Awarded the{' '}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.narom.no/nyheter/lars-henrik-berg-jensen-og-adrian-tofting-fra-ntnu-vant-arets-nifro-pris/"
                >
                    Norwegian Space Industry’s award for best master thesis
                </a>{' '}
                in 2018
            </>
        ),
    },
];

const RecognizedBy: FC = () => (
    <Wrapper>
        <CompanyWrapper>
            {companies.map((company, i) => {
                let imageKey = `company_${i}`;
                return (
                    <GridCell key={imageKey}>
                        <div>
                            <img src={company.img} alt={`${company.title}`} />
                        </div>
                        <TextWrapper>{company.text}</TextWrapper>
                    </GridCell>
                );
            })}
        </CompanyWrapper>
        <ColumnWrapper>
            <span style={{ marginBottom: '20px' }}>Associated with</span>
            <MobileWrapper>
                <TestWrapper>
                    {companies.map((company, i) => {
                        let imageKey = `company_${i}`;
                        return (
                            <MobileCell key={imageKey}>
                                <div>
                                    <img src={company.img} alt={`${company.title}`} />
                                </div>
                                <TextWrapper>{company.text}</TextWrapper>
                            </MobileCell>
                        );
                    })}
                </TestWrapper>
            </MobileWrapper>
        </ColumnWrapper>
    </Wrapper>
);

export default RecognizedBy;
