import { useState } from 'react';

// copied from useInput then minimally modified
export const useInputCheckbox = (
    initialValue: boolean,
    fieldName: string,
    validationRules: Array<(value: boolean, fieldName: string) => string>
): {
    checked: boolean;
    validation: {
        touched: boolean;
        errors: string[];
        validate: () => string[];
    };
    reset: () => void;
    bind: {
        checked: boolean;
        onChange: (event: any) => void;
        onBlur: () => void;
    };
} => {
    const [checked, setChecked] = useState<boolean>(initialValue);
    const [touched, setTouched] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);
    const handleOnChange = (event: any) => {
        if (touched) {
            const errorMessages = validationRules.map((rule) => rule(event.target.checked, fieldName)).filter(Boolean);
            setErrors(errorMessages);
        }
        setChecked(event.target.checked);
    };
    const handleValidation = () => {
        const errorMessages = validationRules.map((rule) => rule(checked, fieldName)).filter(Boolean) || [];
        setTouched(true);
        setErrors(errorMessages);
        return errorMessages;
    };
    return {
        checked,
        validation: {
            touched,
            errors,
            validate: () => handleValidation(),
        },
        reset: () => {
            setChecked(false);
            setTouched(false);
            setErrors([]);
        },
        bind: {
            checked,
            onChange: (event) => handleOnChange(event),
            onBlur: () => handleValidation(),
        },
    };
};
