import { Popup } from 'react-map-gl';
import { ReportType } from '../../../utils/enums';
import { ReportPopup } from '../Popup';

export const StoryPopup: React.FC<{ feature: any; anchor: any }> = ({ feature, anchor }) => {
    let longitude = feature.geometry.coordinates[0];
    let latitude = feature.geometry.coordinates[1];
    return (
        <Popup longitude={longitude} latitude={latitude} offset={30} anchor={anchor}>
            <ReportPopup feature={feature.properties} reportType={ReportType.PathfinderReport} />;
        </Popup>
    );
};
