import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { postContact } from '../requests/crm';
import constants from '../shared/constants';
import { InputButton } from '../shared/styles/Buttons';
import { InputField } from '../shared/styles/InputFields';
import { useInput } from './Report/components/useInput';
import { useInputCheckbox } from './Report/components/useInputCheckbox';

const Wrapper = styled.div<{ bottom?: boolean }>`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    margin-top: 60px;
    padding-bottom: 109px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.15);
    margin-bottom: 100px;

    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        flex-direction: column;
        align-items: center;
    }
`;
const ContactWrapper = styled.div`
    width: calc(${constants.sizes.container} - 50px);
    position: relative;
    max-width: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    padding: 25px;
    border-radius: 5px;
    margin: auto;
    p {
        margin: 0;
        margin-bottom: 10px;
    }
    h2 {
        margin: 0 0 15px 0;
    }
    label {
        font-weight: bold;
        font-size: 10px;
        line-height: 25px;
        /* identical to box height, or 250% */

        letter-spacing: 0.07em;

        color: rgba(255, 255, 255, 0.6);
        a {
            color: rgba(255, 255, 255, 0.6);
        }
    }

    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        width: calc(100% - 50px);
        p {
            margin: 0;
            width: 100%;
        }
    }
`;

const RadialGradientBox = styled.div`
    background-image: radial-gradient(42.95% 171.27% at 86.22% 0%, #00a1ff 0%, rgba(0, 161, 255, 0.4) 100%);
    mix-blend-mode: difference;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;
const InputWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    ${InputButton} {
        margin: 8px 0px;
        @media screen and (max-width: ${constants.breakpoints.mobile}) {
            width: calc(100%);
        }
    }
    ${InputField} {
        width: calc(100%);
        @media screen and (max-width: ${constants.breakpoints.mobile}) {
            width: calc(100%);
            margin-left: 0px;
            margin-right: 0px;
        }
    }
    #email {
        width: calc(100% - 109px);
        @media screen and (max-width: ${constants.breakpoints.mobile}) {
            width: calc(100% - 109px);
        }
    }
    #consentNewsletter {
        width: 16px;
        height: 16px;
        align-self: center;
        margin: 0;
    }
`;
const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        width: 100%;
    }
    isolation: isolate;
`;

const Image = styled.img<{ bottom?: boolean }>`
    position: absolute;
    width: ${(props) => (props.bottom ? '548px' : '962px')};
    bottom: ${(props) => props.bottom && '0px'};
    top: ${(props) => !props.bottom && '0px'};
    right: 0;
`;

export const CardPage: FC<{}> = () => {
    const email = useInput('', 'Email', [required, validEmail]);
    const name = useInput('', 'Name', [required]);
    const companyName = useInput('', 'Company name', [required]);
    const consentNewsletter = useInputCheckbox(false, 'Consent checkbox', [isChecked]);
    const [successfulSubmit, setSuccessfulSubmit] = useState<Boolean | undefined>(undefined);
    const [errormsg, setErrormsg] = useState<string | string[]>('');

    const validInfo = () => {
        let valid = true;
        let allErrors: string[] = [];
        [name, email, companyName, consentNewsletter].forEach((field) => {
            const errors: string[] = field.validation.validate();
            if (errors.length) {
                valid = false;
                allErrors = allErrors.concat(errors);
            }
        });
        setErrormsg(allErrors.slice(0, 1));
        return valid;
    };

    const submitClickup = (e: any) => {
        e.preventDefault();
        if (validInfo()) {
            postContact(name.value, email.value, companyName.value, true, (valid: Boolean) => {
                setSuccessfulSubmit(valid);
            });
        }
    };

    const ResetFields = useCallback(() => {
        email.reset();
        name.reset();
        companyName.reset();
        consentNewsletter.reset();
        setErrormsg('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (successfulSubmit) {
            ResetFields();
        }
    }, [successfulSubmit, ResetFields]);
    return (
        <Wrapper bottom={true}>
            <Image src="images/MapBottom.png" bottom={true} />
            <ContactWrapper>
                <RadialGradientBox />
                {!successfulSubmit ? (
                    <TextWrapper>
                        <h2>Thank you for reaching out </h2>
                        <p>Leave your details below to access a short VAKE pdf-presentation</p>
                        <form onSubmit={(e) => submitClickup(e)}>
                            {/* <label htmlFor="email">Email</label> */}

                            <InputField
                                id="name"
                                type="text"
                                placeholder="Full name"
                                error={name.validation.errors.length > 0}
                                {...name.bind}
                            />
                            <InputField
                                id="companyName"
                                type="text"
                                placeholder="Company name"
                                error={companyName.validation.errors.length > 0}
                                {...companyName.bind}
                            />
                            <InputField
                                id="email"
                                type="email"
                                placeholder="Email"
                                error={email.validation.errors.length > 0}
                                {...email.bind}
                            />
                            <InputButton
                                style={{ color: '#fff' }}
                                background={'#00a1ff'}
                                type="submit"
                                value="Get Presentation"
                            />

                            <InputWrapper style={{ justifyContent: 'start' }}>
                                <InputField
                                    id="consentNewsletter"
                                    type="checkbox"
                                    error={consentNewsletter.validation.errors.length > 0}
                                    {...consentNewsletter.bind}
                                />
                                <label style={{ marginLeft: '8px' }}>
                                    AGREE TO OUR{' '}
                                    <a href="/privacy-policy" target="_blank">
                                        PRIVACY POLICY
                                    </a>{' '}
                                    AND SUBSCRIBE TO OUR OCCASIONAL NEWSLETTER.
                                </label>
                            </InputWrapper>
                        </form>
                        {successfulSubmit && (
                            <span style={{ marginTop: '8px' }}>Newsletter subscription successful</span>
                        )}
                        {errormsg && <span style={{ color: constants.colors.pallet.error }}>{errormsg}</span>}
                    </TextWrapper>
                ) : (
                    <a
                        href={'https://drive.google.com/file/d/1QKmRvD7lamR9PyyU1z4J9S1AyUIgA4GS/view?usp=sharing'}
                        style={{
                            position: 'relative',
                            marginTop: '100px',
                            marginBottom: '100px',
                            alignSelf: 'center',
                            justifySelf: 'center',
                            fontSize: '18px',
                            color: 'orange',
                        }}
                    >
                        Link to downloadable presentation
                    </a>
                )}
            </ContactWrapper>
        </Wrapper>
    );
};

//Validation:
const required = (text: string, fieldName: string) => {
    if (text) {
        return '';
    }
    return `${fieldName} is required`;
};
const isChecked = (value: boolean, fieldName: string) => {
    if (value) {
        return '';
    }
    return `${fieldName} is required to be checked`;
};

const validEmail = (value: string, fieldName: string) => {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
        return '';
    }
    return `${fieldName} is not valid`;
};
