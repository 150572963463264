import { FC } from 'react';
import styled from 'styled-components';
import Analysing from './Analysing';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px auto 200px auto;

    span {
        color: rgba(255, 255, 255, 0.6);
    }
`;

const Footer: FC = () => (
    <Wrapper>
        <div>
            <img src="images/vakeLogo.png" alt="VAKE" />
        </div>
        <Analysing />
        <span>Universitetsgata 2, 0164 Oslo, Norway</span>
        <span>connect@vake.ai</span>
        <span>Tel.: +47 922 50 890</span>
    </Wrapper>
);

export default Footer;
