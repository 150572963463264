import { apiBaseUrl } from '../utils/env';

export const vake_api_server_url = apiBaseUrl();

export const getRequestHeaders = (accessToken: string | null | undefined) => {
    let headers = new Headers({
        'Content-Type': 'application/json',
    });

    if (accessToken) {
        headers.append('Authorization', 'Bearer ' + accessToken);
    }

    return headers;
};
