// Returns a date string on this format YYYY-MM-DD
export const getDateISOString = (dateObject: Date) => dateObject.toISOString().slice(0, 10);

export const isValidDateString = (input: string): boolean => {
    const test = new Date(input);
    return !isNaN(test.getTime());
};

export const findIndexOfDateInArray = (dateArray: Date[], queryDate: Date): number => {
    const tempDateDiffs = dateArray.map((d) => Math.abs(queryDate.getTime() - d.getTime())); // Get time difference to all datobjects
    const closestDateIndex = tempDateDiffs.indexOf(Math.min(...tempDateDiffs)); // Get index of smallest timedifference
    return closestDateIndex;
};
