import { FC, useState } from 'react';
import styled from 'styled-components';
import constants from '../../../shared/constants';

const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const Wrapper = styled(ColumnWrapper)`
    width: ${constants.sizes.container};
    max-width: calc(100% - 50px);
    margin: auto;
    margin-top: 150px;
`;
const FaqWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        flex-direction: column;
    }
`;
const PageSection = styled(ColumnWrapper)`
    width: calc(50% - 30px);
    h2 {
        margin: 15px 0;
    }
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        width: 100%;
    }
`;
const FaqSection = styled(ColumnWrapper)`
    margin-bottom: 50px;
`;
interface IFaqItem {
    question: string;
    answer: JSX.Element;
}
const Technology: IFaqItem[] = [
    {
        question: 'How does your ship detection work?',
        answer: (
            <>
                In short, we detect ships in satellite imagery with using state-of-the art machine learning and data
                fusion. We get images from ESAs two Sentinel satellites and automatically detect ships in the photos.
                The results are then delivered to our customer by a report, through an API, or as a direct integration
                into their systems.
                <br />
                <br />
                This year we’re launching automated ship detections, cross-referenced with other vessel monitoring
                solutions - such as AIS. <a href="mailto:connect@vake.ai">Reach out</a> if this is of interest, we have
                already started the presale.
            </>
        ),
    },
    {
        question: 'What kind of training data do you use?',
        answer: (
            <>
                At Vake we generate our own training data by fusing satellite images and other data sources – such as
                AIS. This allows us to automatically generate any amount of training data for ships of various sizes,
                directions, headings, etc. Using this system, we can train our algorithm on different image sources and
                provide a unique flexibility for our customers.{' '}
            </>
        ),
    },
    {
        question: 'Can you train your algorithm for other satellites?',
        answer: (
            <>
                Yes, the method for generating training is general and can be applied to any satellite image sources
                according to your needs.{' '}
                <a target="_blank" rel="noopener noreferrer" href="https://calendly.com/vake/vake-intro">
                    Book a meeting with us
                </a>{' '}
                to discuss the availability or usage of other satellite sources.
            </>
        ),
    },
    {
        question: 'How precise is the ship detection? ',
        answer: (
            <>
                Vake’s unique methodology for generating training data of images of ships of various sizes, directions,
                speeds, and other parameters, ensures the highest possible accuracy for applying machine learning in our
                current data source. The same methodology can also be applied to new data sources according to user
                need. The pinpoint accuracy of Vake’s algorithms can also be customized according to user needs,
                depending e.g. on whether or not there are human controls in place (humans-in-the-loop) to go through
                the data for verification or intelligence purposes. This means our customer can fine-tune the optimal
                ratio of false positives/negatives.
            </>
        ),
    },
    {
        question: 'How is your vessel monitoring system different from other satellite data analytics companies?',
        answer: (
            <>
                We differ from other satellite data analytics companies by having a pure maritime focus. AI and machine
                learning methods cannot be generalised across domains. Our team, ship detections, algorithms, and data
                fusion is trained and specialized to provide one-of-a-kind maritime insights.{' '}
            </>
        ),
    },
];

const LegalPrivacy: IFaqItem[] = [
    {
        question: 'Our privacy policy',
        answer: (
            <>
                You can find{' '}
                <a href="/privacy-policy" target="_blank">
                    our policy here
                </a>
            </>
        ),
    },
];

const LegalConfidentiality: IFaqItem[] = [
    {
        question: 'Confidentiality',
        answer: (
            <>
                For contracts: Methods for data delivery will be discussed and agreed upon by both parties.
                <br />
                <br />
                Demo reports: Requests through this website will be treated confidentially.
                <br />
                <br />
                The data you receive: As a customer you have the right to use the data you receive from us as pleased,
                but you do not have the right to resell it.
            </>
        ),
    },
];

const Use: IFaqItem[] = [
    {
        question: 'What areas do you cover?',
        answer: (
            <>
                Our current coverage follows the image acquisition plans of the European Space Agency’s Sentinel-2
                captures. Generally speaking, this image source covers areas that are 300 kms wide for each pass, and up
                to 15.000 km in length, which provides better area coverage than most commercially available sources.
                You can get the full overview of the Sentinel-2 coverage{' '}
                <a
                    href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-2-msi/revisit-coverage"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    here
                </a>{' '}
                or just get in touch, we are happy to provide the exact coverage for a given area.
                <br />
                <br />
                We are flexible in terms of satellite source and can provide coverage beyond Sentinel-2 upon request.
            </>
        ),
    },
    {
        question: 'How do you deliver the data?',
        answer: (
            <>
                Data from our analysis can be delivered either in a report, through our API, as a direct integration or
                in separate data files through secure file transfers.{' '}
            </>
        ),
    },
    {
        question: 'Is it easy to use? Does it require a lot of training?',
        answer: (
            <>
                The data is easy to use and to obtain. We deliver the data in a format that best suits your needs and
                workflow. We are happy to provide any clarification and answer any questions related to this in a brief{' '}
                <a href="https://calendly.com/vake/vake-intro" target="_blank" rel="noopener noreferrer">
                    introductory meeting
                </a>
                .{' '}
            </>
        ),
    },
    {
        question: 'Can I get data points at night?',
        answer: (
            <>
                Currently, no. Our current satellite data source is optical which means it depends on daylight, and does
                not image during the night. However, we work to include SAR (Synthetic Aperture Radar) sources in the
                near future, <a href="mailto:connect@vake.ai">get in touch</a> to learn more about our product
                development plans.{' '}
            </>
        ),
    },
    // ,
    // {
    //     question: '',
    //     answer: <></>
    // },
    // {
    //     question: '',
    //     answer: <></>
    // },
    // {
    //     question: '',
    //     answer: <></>
    // }
];
const General: IFaqItem[] = [
    {
        question: 'Where is Vake in 2030?',
        answer: (
            <>
                Our vision is to be at the forefront of technological development of satellite image processing. We aim
                to expand our product portfolio with a suite of vessel monitoring solutions - developed and offered
                based on customer needs.{' '}
            </>
        ),
    },
];

export const FAQ: FC = () => {
    return (
        <Wrapper>
            <h1>Common questions</h1>
            <FaqWrapper>
                <PageSection>
                    <FaqSection>
                        <h2>Technology</h2>
                        {Technology.map((item, i) => (
                            <FAQItem key={`faq_technology_${i}`} {...item} />
                        ))}
                    </FaqSection>
                    <FaqSection>
                        <h2>Legal</h2>
                        {LegalPrivacy.map((item, i) => (
                            <FAQItem key={`faq_legal_${i}`} {...item} />
                        ))}
                        {LegalConfidentiality.map((item, i) => (
                            <FAQItem key={`faq_legal_1_${i}`} {...item} />
                        ))}
                    </FaqSection>
                </PageSection>
                <PageSection>
                    <FaqSection>
                        <h2>Use</h2>
                        {Use.map((item, i) => (
                            <FAQItem key={`faq_use_${i}`} {...item} />
                        ))}
                    </FaqSection>
                    <FaqSection>
                        <h2>General</h2>
                        {General.map((item, i) => (
                            <FAQItem key={`faq_general_${i}`} {...item} />
                        ))}
                    </FaqSection>
                </PageSection>
            </FaqWrapper>
        </Wrapper>
    );
};

const FaqItemWrapper = styled(ColumnWrapper)`
    margin-bottom: 15px;
    p {
        margin: 0px;
        margin-top: 10px;
    }
`;
const Question = styled.span<{ open?: boolean }>`
    font-weight: ${(props) => (props.open ? '700' : 'normal')};
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    cursor: pointer;
    font-size: 17px;
    line-height: 25px;
`;
const FAQItem: FC<IFaqItem> = ({ question, answer }) => {
    const [open, setOpen] = useState<boolean>(false);
    return (
        <FaqItemWrapper>
            <div>
                <Question open={open} onClick={() => setOpen(!open)}>
                    {open ? '-' : '+'} {question}
                </Question>
            </div>
            {open && <p>{answer} </p>}
        </FaqItemWrapper>
    );
};
