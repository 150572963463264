import styled from 'styled-components';

type circleProps = {
    fill: string;
    stroke: string;
    size?: number;
};

export const CircleDot = styled.div<circleProps>`
    display: inline-block;
    width: ${(props) => (props.size ? props.size : 10)}px;
    height: ${(props) => (props.size ? props.size : 10)}px;
    background: ${(props) => props.fill};
    border: 1px solid ${(props) => props.stroke};
    border-radius: 50%;
    margin-right: 15px;
    max-width: 12px;
`;
