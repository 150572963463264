import { useQuery } from 'react-query';
import { useAuth } from '../auth/AuthProvider';
import { apiBaseUrl } from '../utils/env';
import { getRequestHeaders } from './helpers';
import { FeatureCollection, Geometry } from 'geojson';
import { Candidate } from '../pages/CandidateList';
import { stringify } from 'wkt';

const vake_api_server_url = apiBaseUrl();

export const useInsightByUuid = (uuidList: string[] | null) => {
    const { token } = useAuth();
    const uuidListBody = { uuid_list: uuidList };
    const url = `${vake_api_server_url}/insights/uuid`;
    return useQuery<FeatureCollection>(
        ['insights_by_uuid', uuidList],
        async () => {
            const res = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: getRequestHeaders(token),
                body: JSON.stringify(uuidListBody),
            });

            if (res.status === 404) {
                console.log(await res.json());
            }

            if (!res.ok) {
                throw new Error(res.statusText);
            }

            return await res.json();
        },
        {
            staleTime: Infinity,
            enabled: !!uuidList,
            cacheTime: 1000 * 60 * 60,
        }
    );
};

export const useClearviewCandidates = (
    uuidList: string[] | null,
    fromDate: Date | null,
    toDate: Date | null,
    aoi: Geometry | null,
    setClearviewErrorMsg: (value: string | null) => void,
    num_results: Number | null = 30
) => {
    const { token } = useAuth();

    const baseUrl = `${vake_api_server_url}/clearview/`;

    // Create query parameters
    const queryParams = new URLSearchParams({});
    if (num_results) {
        queryParams.append('num_results', num_results.toString());
    }

    if (uuidList) {
        uuidList.forEach((uuid) => {
            queryParams.append('uuid_list', uuid);
        });
    }

    if (aoi) {
        queryParams.append('area_of_interest', stringify(aoi));
    }

    if (fromDate) {
        queryParams.append('from_date', fromDate.toISOString());
    }

    if (toDate) {
        queryParams.append('to_date', toDate.toISOString());
    }

    const fullUrl = `${baseUrl}?${queryParams.toString()}`;

    return useQuery<Candidate[]>(
        ['clearviewCandidates', uuidList, fromDate, toDate, aoi],
        async () => {
            const res = await fetch(fullUrl, {
                method: 'GET',
                credentials: 'include',
                headers: getRequestHeaders(token),
            });

            if (!res.ok) {
                if (res.status === 400) {
                    const errorMessage = await res.text();
                    throw new Error(errorMessage);
                } else if (res.status === 401) {
                    throw new Error('Unauthorized');
                }
                throw new Error(`Error fetching data: ${res.statusText}`);
            }

            return await res.json();
        },
        {
            staleTime: Infinity,
            enabled: !!uuidList,
            cacheTime: 1000 * 60 * 60,
            retry: 2,
            onError: (error: any) => {
                if (error instanceof Error && error.message === 'Unauthorized') {
                    setClearviewErrorMsg(error.message);
                } else {
                    setClearviewErrorMsg(`Failed to fetch Clearview candidates data: ${error.message}`);
                }
            },
        }
    );
};

export const useClearviewMmsiExample = (mmsi: number | null, setClearviewErrorMsg: (value: string | null) => void) => {
    const { token } = useAuth();
    const url = `${vake_api_server_url}/insights/mmsi`;

    return useQuery<FeatureCollection>(
        ['getMmsi', mmsi],
        async () => {
            const res = await fetch(`${url}?mmsi=${mmsi}`, {
                method: 'GET',
                credentials: 'include',
                headers: getRequestHeaders(token),
            });
            if (!res.ok) {
                if (res.status === 401) {
                    throw new Error('Unauthorized');
                }
                throw new Error(`Error fetching data: ${res.statusText}`);
            }
            return await res.json();
        },
        {
            staleTime: Infinity,
            enabled: !!mmsi,
            cacheTime: 1000 * 60 * 60,
            retry: 2,
            onError: (error: any) => {
                if (error instanceof Error && error.message === 'Unauthorized') {
                    setClearviewErrorMsg(error.message);
                } else {
                    setClearviewErrorMsg(`Failed to fetch Clearview MMSI data: ${error.message}`);
                }
            },
        }
    );
};
