import styled from 'styled-components';
import constants from '../constants';

export const PageContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: ${constants.sizes.container};
    width: calc(100% - 40px);
    margin: auto;
    margin-top: 230px;

    h2 {
        margin-bottom: 15px;
    }

    @media screen and (max-width: ${constants.breakpoints.miniTablet}) {
        margin-top: 50px;
    }
`;
