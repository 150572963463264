import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { setConsentCookie } from '../../utils/cookies';
import { hotjar } from 'react-hotjar';
import { Button } from '../styles/Buttons';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
    position: fixed;
    z-index: 10000;
    top: 0px;
    width: 100vw;
    color: black;
`;

const Banner = styled.div`
    background: #e7e7e7;
    margin: auto;
    width: calc(100% - 30px);
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
        color: black;
    }
`;
const StyledButton = styled(Button)`
    background: rgba(0, 0, 0, 0.1);
    border: 2px solid black;
    color: black;
    padding: 5px 10px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const ButtonWrapper = styled.div`
    display: flex;
    button {
        margin-left: 10px;
    }
`;

export const CookieConsent: FC<{
    visible: Boolean;
    setVisibility: Function;
    consented: Boolean;
    setConsented: Function;
}> = ({ visible, setVisibility, consented, setConsented }) => {
    const handleConsentClick = () => {
        setConsented(true);
        setConsentCookie();
    };

    useEffect(() => {
        if (consented) {
            hotjar.initialize(2236246, 6);
        }
    }, [consented]);

    return consented ? null : visible ? (
        <Wrapper>
            <Banner>
                <span>
                    We use <Link to="/privacy-policy">cookies</Link>, in order to improve our site
                </span>
                <ButtonWrapper>
                    <StyledButton onClick={() => setVisibility(false)}>
                        <img src="/images/icons/cross.png" alt="Close cookies" />
                    </StyledButton>
                    <StyledButton onClick={handleConsentClick}>Accept</StyledButton>
                </ButtonWrapper>
            </Banner>
        </Wrapper>
    ) : null;
};
