import { FC, useEffect } from 'react';
import styled from 'styled-components';
import constants from '../shared/constants';
import { PageContentWrapper } from '../shared/styles/wrappers';

const IntroWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    p {
        margin: 0 auto 60px auto;
    }
    margin-bottom: 50px;
    @media screen and (max-width: ${constants.breakpoints.miniTablet}) {
        flex-direction: column;
        margin-top: 50px;
        p {
            margin-bottom: 30px;
        }
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ImageTxt = styled.span`
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.03em;
    max-width: 238px;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 45px;
`;
const ColumnWrapper = styled.div`
    width: calc(50% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
        max-width: 400px;
        margin: auto;
    }
    @media screen and (max-width: ${constants.breakpoints.miniTablet}) {
        width: 100%;
        img {
            margin-bottom: 30px;
            width: 100%;
        }
    }
`;
const StyledHeader = styled.h1`
    margin-bottom: 75px;
    @media screen and (max-width: ${constants.breakpoints.miniTablet}) {
        margin-bottom: 30px;
    }
`;

const FoundersWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
`;
const Founder = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
    @media screen and (max-width: ${constants.breakpoints.miniTablet}) {
        flex-basis: 100%;
    }
`;

const HighlightedFounder = styled.div`
    display: flex;
    img {
        width: 70px;
        height: 70px;
        border-radius: 5px;
    }
    div {
        margin-left: 15px;
        display: flex;
        flex-direction: column;
        span {
            color: rgba(255, 255, 255, 0.6);
        }
    }
`;

const Video = styled.video`
    width: 500px;
    height: auto;
    max-width: calc(100% - 20px);
    margin: auto;
`;

interface IFounders {
    header: string;
    email: string;
    role: string;
    text: JSX.Element;
    img: string;
}
const founders: IFounders[] = [
    {
        header: 'Thomas Stendahl Leira',
        email: 'thomas@vake.ai',
        role: 'Chief Executive Officer',
        text: (
            <>
                M.Sc. in Industrial Economics and Technology Management from NTNU. Thomas has nine years of experience
                in product and business development, and strategy and innovation methods, through work in Telenor
                Norway, Telenor Digital and DNV GL. He’s often found ski touring Norway’s incredible mountain and fjord
                scenery.
            </>
        ),
        img: 'images/about/Thomas.jpeg',
    },
    {
        header: 'Adrian Tofting',
        email: 'adrian@vake.ai',
        role: 'Chief Product Officer',
        text: (
            <>
                M.Sc. in Geomatics and Computer Science from NTNU. Adrian has several years of experience with technical
                development and processing of spatial data. He has worked on service and technology development for both
                Norkart and Iterate. During his studies Adrian was part of a team that built an autonomous flying drone.
            </>
        ),
        img: 'images/about/Adrian.jpeg',
    },
    {
        header: 'Lars Henrik Berg-Jensen',
        email: 'lars@vake.ai',
        role: 'Chief Technology Officer',
        text: (
            <>
                M.Sc. in Geomatics and Computer Science from NTNU. Lars has great interest and knowledge in gathering,
                processing and creating value from data. Through studies and work in Iterate, Cisco and Norkart, he has
                gained a wide range of experience: in everything from fluid mechanics calculations to development of
                cloud-based machine learning services. He is also an avid football enthusiast.
            </>
        ),
        img: 'images/about/Lars.jpeg',
    },
    {
        header: 'Tugba Gunes',
        email: 'tugba@vake.ai',
        role: 'Chief Commercial Officer',
        text: (
            <>
                M.Sc in Political Science from the University of Oslo. Tugba has over 7 years of experience in military
                diplomacy, advising in the defence sector and entrepreneurship. She has worked in the Norwegian
                Embassies of Warsaw and Baku, Norwegian Defence Material Agency and founded her own company. She is a
                dancer, former fencer and is passionate about travel.
            </>
        ),
        img: 'images/about/tugba.jpeg',
    },
    {
        header: 'Odd Eirik Igland',
        email: 'oddeirik@vake.ai',
        role: 'Chief Information Officer',
        text: (
            <>
                M.Sc. in Artifical Intelligence and Computer Science from NTNU. Odd Eirik has cultivated his interests
                within AI and automization throughout his studies and internships in Statnett, Netlight and Birdsview.
                Odd Eirik is an avid mountaineer and former speed skater, a true Norseman.
            </>
        ),
        img: 'images/about/Oddeirik.jpeg',
    },
    {
        header: 'Torstein Solberg',
        email: 'torstein@vake.ai',
        role: 'Chief Data Officer',
        text: (
            <>
                M.Sc. in Geomatics and Computer Science from NTNU. With internships in MedTech, FinTech and consultancy
                firms, Torstein brings much software development knowledge to the team. At NTNU, Torstein was highly
                engaged in student activities, serving as Leader in his Student Association, among other things.
            </>
        ),
        img: 'images/about/Torstein.jpeg',
    },
    {
        header: 'Sindre Hestnes Kaald',
        email: 'sindre@vake.ai',
        role: 'Chief Scientific Officer',
        text: (
            <>
                M.Sc. in physics and mathematics from NTNU. As a former researcher at IFE, Sindre had the rare
                experience of working at an operational nuclear reactor. In the summer, he annoys Oslo's drivers on his
                road bike. During winter, he’s heading deep into the woods, collecting miles on two skis.
            </>
        ),
        img: 'images/about/Kaald.jpg',
    },
    {
        header: 'Eilef Osvik',
        email: 'eilef.osvik@vake.ai',
        role: 'Data Analyst',
        text: (
            <>
                M.Sc in Cybernetics and Robotics from NTNU. Eilef has, through studies, deep-dived into the world of
                hardware and optimization with a specialization in reinforcement learning for drone control. Engagements
                in student activities and internships have helped form this engaging and reliable team player. Eilef
                enjoys playing music with friends and handling the guitar and piano well.
            </>
        ),
        img: 'images/about/eilef.jpg',
    },
    {
        header: 'Andrew Farabow',
        email: 'andrew@vake.ai',
        role: 'Data Analyst',
        text: (
            <>
                B.S. in Computer Science from Virginia Tech. Andrew has worked on a diverse range of machine learning
                projects through internships (including a summer at Vake) and research work at Virginia Tech. In his
                free time, he enjoys cooking, brewing beer, and going to the gym.
            </>
        ),
        img: 'images/about/placeholder.jpg',
    },
];

const AboutUs: FC = () => {
    useEffect(() => {
        document.title = 'About us';
    }, []);
    return (
        <PageContentWrapper>
            <IntroWrapper>
                <ColumnWrapper>
                    <StyledHeader>About us</StyledHeader>
                    <p>
                        Vake is a Norwegian machine learning company at the cutting edge of satellite guided Maritime
                        Domain Awareness. We differ from other satellite data analytics companies with our pure maritime
                        focus, a focus that makes us uniquely positioned to extract and provide deep insights across
                        multiple maritime data sources.
                    </p>
                    <p>
                        Illegal fishing, assets protection and environmental crimes are immense global challenges. The
                        breakdown of our amphibious ecosystems will affect us for generations to come. Total Maritime
                        Domain Awareness is an important step towards resolving these problems. This is a combined
                        effort, and we want to do our part by providing transparency and accountability for any activity
                        at sea. Are you working towards the same goal? <a href="mailto:connect@vake.ai">Reach out</a> –
                        we would love to discuss potential partnerships.
                    </p>
                    <h2>Our story</h2>
                    <p>
                        Vake has been working with satellite imagery and vessel traffic since 2017. The technological
                        foundation for Vake was laid during the two technical founders’ award winning master thesis:{' '}
                        <i>A Big Data Approach to Generate Training Data for Automatic Ship Detection.</i>
                        The theoretical effort has since evolved into a groundbreaking company, processing an extensive
                        volume of satellite imagery on a daily basis. Vake is a highly scalable data-first company, but
                        our team is lean and flexible in accommodating customers' needs.
                    </p>
                </ColumnWrapper>
                <ImageWrapper>
                    <Video autoPlay muted loop>
                        <source src="images/about/Sentinel.mp4" type="video/mp4" />
                    </Video>
                    <ImageTxt>Visualization of Sentinel 2A in orbit, one of our two main data sources</ImageTxt>
                </ImageWrapper>
            </IntroWrapper>
            <h2>Our team</h2>
            <FoundersWrapper>
                {founders.map((f, i) => (
                    <Founder key={i}>
                        <HighlightedFounder>
                            <img src={f.img} alt={`${f.role}`} />
                            <div>
                                <b>{f.header}</b>
                                <span>{f.email}</span>
                                <span>{f.role}</span>
                            </div>
                        </HighlightedFounder>
                        <p>{f.text}</p>
                    </Founder>
                ))}
            </FoundersWrapper>
        </PageContentWrapper>
    );
};

export default AboutUs;
