import { FC, useState, createContext, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FlyToInterpolator } from 'deck.gl/typed';

import StoryMap from './Map';
import Story from './Story';
import ProgressBar from './ProgressBar';
import ScrollAnimation from './ScrollAnimation';
import { Viewport, Context, PopupInterface, DeckglLayerTypes, MapboxLayerType } from './types/context';

import { useStoryData } from '../../../requests/reports';
import { AbsoluteSpinner } from '../../../pages/Report/interactiveReport/PathfinderReport';
import { easeCubicInOut } from 'd3-ease';
import { Arrow, TimeSinceLastConfirmedAis } from '../../../pages/Report/interactiveReport/reportInfo/ReportInfo';
import StoryButton from './StoryButton';
import { ShipInfo, SideBar } from '../../../pages/Report/components/OverwatchRight';
import { RoundedWrapper } from '../../../pages/Report/styles/Wrappers';

const Root = styled.div`
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
`;

const PagingBox = styled.div`
    position: fixed;
    top: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5000;
`;

const ScrollBox = styled.div`
    position: fixed;
    bottom: 4rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5000;
`;

const ButtonsBox = styled.div`
    position: fixed;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    left: 3rem;
    bottom: 3rem;
    z-index: 50000;
    row-gap: 5px;
`;

const ArrowBox = styled.div`
    display: flex;
    justify-content: flex-start;
    column-gap: 5px;
`;

const StyledArrow = styled(Arrow)`
    width: 70px;
    height: 70px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 1);
    margin-left: 0px;
`;

const RotatedImg = styled.img`
    transform: rotate(90deg);
    width: 35px;
    height: 35px;
`;

const defaultViewport: Viewport = {
    latitude: 49.915862,
    longitude: 10.046997,
    zoom: 4,
    bearing: 0,
    pitch: 60,
    transitionDuration: 500,
    transitionInterpolator: new FlyToInterpolator(),
    transitionEasing: easeCubicInOut,
};

const defaultState: Context = {
    state: {
        isJourneyMode: false,
        viewport: defaultViewport,
        deckglLayers: [],
        isButtonDisabled: false,
        activeStep: null,
        currentScrollTop: 0,
        popups: [],
        mapboxLayers: [],
        ranges: [],
        lastConfirmedAisTimestamp: undefined,
    },
    actions: {},
};

export const AppContext = createContext(defaultState);

const AppScreen: FC = () => {
    const [viewport, setViewport] = useState(defaultViewport);
    const [isJourneyMode, setIsJourneyMode] = useState(true);
    const [deckglLayers, setDeckglLayers] = useState<DeckglLayerTypes[]>([]);
    const [popups, setPopups] = useState<PopupInterface[]>([]);
    const [mapboxLayers, setMapboxLayers] = useState<MapboxLayerType[]>([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [activeStep, setActiveStep] = useState<number | null>(null);
    const [currentScrollTop, setCurrentScrollTop] = useState(0);
    const [lastItem, setLastItem] = useState<Boolean>(false);
    const [lastConfirmedAisTimestamp, setLastConfirmedAisTimestamp] = useState<Date | undefined>(undefined);

    useEffect(() => {
        document.title = 'Interactive story';
    }, []);

    let ranges: number[] = useMemo(() => [], []);

    const params = useParams();
    const { data: storyData, isLoading } = useStoryData(params);

    const onEnableMap = (enable: boolean) => {
        setIsJourneyMode(!enable);
    };

    // useEffect for setting last item true or false
    useEffect(() => {
        if (storyData && storyData.chapters.length - 1 === activeStep) {
            setLastItem(true);
        } else {
            setLastItem(false);
        }
    }, [storyData, activeStep]);

    const programmaticScroll = (scrollPosition: number) => {
        window.scrollTo({ top: scrollPosition });
        setCurrentScrollTop(scrollPosition);
    };

    const jumpToNextChapter = () => {
        if (!activeStep || activeStep === 0) {
            // If step == 0 or null -> active step = 1 -> range = ranges[0] -> first scrollTopRange
            programmaticScroll(ranges[0]);
        } else if (activeStep > 0 && !lastItem) {
            // If step > 0 -> active step = active step + 1 -> ranges[activeStep + 1 - 1] -> ranges[activeStep]
            programmaticScroll(ranges[activeStep]);
        }
    };

    const jumpToPreviousChapter = () => {
        if (activeStep && activeStep === 1) {
            // if step == 1 -> active step = 0 -> scrolltop = 0
            programmaticScroll(0);
        } else if (activeStep && activeStep > 1) {
            // if step > 1 -> active step = active step - 1  -> ranges[activeStep -1 -1] -> ranges[activeStep - 2]
            programmaticScroll(ranges[activeStep - 2]);
        }
    };

    const differenceInDays = (date1: Date, date2: Date) => {
        const timediff = date2.getTime() - date1.getTime();
        return Math.abs(Math.round(timediff / (1000 * 3600 * 24)));
    };

    return (
        <AppContext.Provider
            value={{
                state: {
                    isJourneyMode,
                    viewport,
                    deckglLayers,
                    isButtonDisabled,
                    activeStep,
                    currentScrollTop,
                    popups,
                    mapboxLayers,
                    ranges,
                    lastConfirmedAisTimestamp,
                },
                actions: {
                    setIsJourneyMode,
                    setViewport,
                    setDeckglLayers,
                    setIsButtonDisabled,
                    onEnableMap,
                    setActiveStep,
                    setCurrentScrollTop,
                    setPopups,
                    setMapboxLayers,
                    setLastConfirmedAisTimestamp,
                },
            }}
        >
            <Root>
                {isLoading && <AbsoluteSpinner />}
                {!isLoading && activeStep !== null && storyData?.chapters && isJourneyMode && (
                    <PagingBox>
                        <ProgressBar step={activeStep} totalSteps={storyData.chapters.length - 1} />
                    </PagingBox>
                )}
                {!isLoading && activeStep !== null && isJourneyMode && (
                    <ScrollBox>
                        <ScrollAnimation text="Scroll or use the buttons to the left to view story." />
                    </ScrollBox>
                )}
                {!isLoading && activeStep !== null && storyData?.chapters && (
                    <ButtonsBox>
                        <StoryButton
                            activeStep={activeStep}
                            isJourneyMode={isJourneyMode}
                            onEnableMap={(is: boolean) => onEnableMap(is)}
                            storyButtonDisabled={false}
                        />
                        <ArrowBox>
                            <StyledArrow
                                disabled={!activeStep || activeStep === 0 || !isJourneyMode}
                                onClick={() => jumpToPreviousChapter()}
                            >
                                <RotatedImg src="/images/icons/ArrowLeft.svg" alt="Previous chapter" />
                            </StyledArrow>
                            <StyledArrow disabled={lastItem || !isJourneyMode} onClick={() => jumpToNextChapter()}>
                                <RotatedImg src="/images/icons/ArrowRight.svg" alt="Next chapter" />
                            </StyledArrow>
                        </ArrowBox>
                    </ButtonsBox>
                )}
                {!isLoading && <StoryMap mapStyle={storyData?.mapboxStyle} />}
                {!isLoading && storyData && <Story data={storyData} />}
                {popups && popups.length === 1 && popups[0]?.feature && (
                    // When single popup use sidebar, else uses popups
                    <>
                        {lastConfirmedAisTimestamp && (
                            <RoundedWrapper>
                                <TimeSinceLastConfirmedAis>
                                    Time since last verified AIS message:
                                    <br />
                                    <h2>
                                        {differenceInDays(
                                            new Date(popups[0].feature?.properties?.timestamp),
                                            new Date(lastConfirmedAisTimestamp)
                                        )}{' '}
                                        days
                                    </h2>
                                </TimeSinceLastConfirmedAis>
                            </RoundedWrapper>
                        )}
                        <SideBar>
                            <ShipInfo
                                selectedFeature={popups[0].feature}
                                setBoatMetaData={() => null}
                                shipName={
                                    popups[0].feature.properties?.shipname
                                        ? popups[0].feature.properties.shipName
                                        : null
                                }
                                upsampleImage={true}
                            />
                        </SideBar>
                    </>
                )}
            </Root>
        </AppContext.Provider>
    );
};
export default AppScreen;
