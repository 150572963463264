import { FC } from 'react';
import styled from 'styled-components';
import Intro from './Intro';
import Description from './Description';
import RecognizedBy from './RecognizedBy';
import constants from '../../../shared/constants';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Container = styled(Wrapper)`
    margin: auto;
    max-width: ${constants.sizes.container};
    @media screen and (max-width: ${constants.breakpoints.mobile}) {
        width: calc(100% - 50px);
    }
`;

const Why: FC = () => (
    <Wrapper>
        <Intro />
        <Container>
            <Description />
        </Container>
        <RecognizedBy />
    </Wrapper>
);

export default Why;
