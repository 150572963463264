import { Outlet, useLocation, useParams } from 'react-router-dom';
import NotFound from '../pages/NotFound';
import { useValidateReportId } from '../requests/reports';
import styled from 'styled-components';
import { Spinner } from '../shared/styles/Spinner';
import { whiteListedReports } from './ProtectedContent';

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
`;

const AbsoluteSpinner = styled(Spinner)`
    position: absolute;
`;

const getReportTypeByUrlPath = (pathName: string) => {
    return /overwatch/.test(pathName) ? 'overwatch' : /pathfinder/.test(pathName) ? 'pathfinder' : 'story';
};

export const ReportContent: React.FC = () => {
    const location = useLocation();
    const params = useParams();
    const pathName = location.pathname;
    const reportTypeUrlString = getReportTypeByUrlPath(pathName);
    const isWhitelisted: boolean = params.id !== undefined && whiteListedReports.includes(params.id);
    const validationNeeded = !isWhitelisted;

    const { data: isValid, isLoading } = useValidateReportId(params, reportTypeUrlString, validationNeeded);

    if (validationNeeded && isLoading) {
        return (
            <Wrapper>
                <AbsoluteSpinner />
            </Wrapper>
        );
    }

    if (validationNeeded && (!params.id || !isValid)) {
        return <NotFound location={window.location.href} />;
    }

    return <Outlet />;
};
