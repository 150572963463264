import React, { useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { apiLogin } from '../requests/auth';

type AuthContextType = {
    token: string | null | undefined;
    login: (username: string, password: string, loginFailedCallback: Function) => void;
    logout: () => void;
};

interface Props {
    children: React.ReactNode;
}

const AuthContext = React.createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<Props> = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [token, setToken, remove] = useLocalStorage<string | null>('token', null);

    const origin = location.state?.from?.pathname || '/';

    const login = useCallback(
        async (username: string, password: string, loginFailedCallback: Function) => {
            apiLogin(username, password, (accessToken: string | null | undefined) => {
                if (accessToken) {
                    setToken(accessToken);
                    navigate(origin);
                    loginFailedCallback(false);
                } else {
                    loginFailedCallback(true);
                    // TODO: Implement timeout or serverside CAPTCHA or maybe do nothing.
                }
            });
        },
        [origin, navigate, setToken]
    );

    const logout = useCallback(() => {
        remove();
    }, [remove]);

    const value: AuthContextType = useMemo(() => ({ token, login, logout }), [token, login, logout]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return React.useContext(AuthContext) as AuthContextType;
};
